@media (max-width: 576px) {
  .btn {
    font-size: 0.9rem;
  }

  .menu__link {
    font-size: 1rem;
  }

  .logo {
    img {
      width: 100px;
    }
  }

  .hikaye--1 {
    .hikaye__bg {
      width: 100%;
    }

    .hikaye__card {
      width: calc(100% - 30px);
      margin-left: 15px;
    }

    .hikaye__gorsel {
      position: relative;
      top: 0;
      left: 0;
    }
  }

  .hikaye-wrapper {
    padding: 15px;
  }

  .hikaye__card,
  .sef-hikaye-yazi {
    font-size: 0.9rem;
  }

  .hikaye--2 .hikaye__gorsel--2 {
    right: -15px;
  }

  .hikaye--3 .hikaye__card p {
    font-size: 1rem;
  }

  .sef-hikaye-bg {
    left: -30px;
    width: calc(100% + 60px);
  }

  .quote {
    width: 100%;
    padding: 60px 15px;
    font-size: 1.2rem;

    &:after,
    &:before {
      width: 30px;
      height: 30px;
    }
  }

  .mutfak-baslik {
    font-size: 2.2rem;
  }

  .hakkimizda {
    &__item {
      width: 100%;
    }

    &--2 {
      flex-direction: column-reverse;
    }
  }

  .urun {
    width: 150px;
  }

  .menu-ust {
    margin-bottom: 30px;
  }

  .menu-bg {
    background-image: url("../img/bg.jpg") !important;
  }

  .etkinlik {
    width: 300px;
    margin-right: 15px;

    &__bg {
      height: 200px;
    }
  }

  .icerik {
    padding: 15px 15px 50px;
  }

  .sayfa__wrapper {
    width: calc(100% - 30px);
    padding: 15px;
  }

  .iletisim-box {
    font-size: 1rem;
  }

  .paket--wrapper {
    width: calc(100% - 30px);
  }

  .servis {
    margin-right: 0;
  }

  .sepet-adetler {
    flex-wrap: wrap;
  }

  .sepet-fiyat {
    width: 100%;
    display: block;
    margin: 15px 0;
  }

  .sepet-adetler {
    margin-top: 0 !important;

    strong {
      display: block;
      width: 100%;
    }

    .servis__number,
    select {
      margin-left: 0 !important;
      margin-top: 15px;
    }
  }

  .slider-detay__resim {

  }

  .site {
    width: 100%;
    max-width: 100%;
  }

  .navigation {
    background: none;
    height: 150px;
    box-shadow: none;
    z-index: 10001;
  }

  .slider-detay__baslik {
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 10px;
  }

  .slider-detay__yazi {
    font-size: 0.9rem;
  }

  .slider-detay {
    padding-top: 100px;
    padding-bottom: 190px;
  }

  .navigation__arrow {
    display: none;
  }

  .paket {
    padding: 3px 8px;
    font-size: 0.8rem;
  }

  .image__text {
    font-size: 0.9rem;
  }

  .slider {
    bottom: 66px;
  }

  .image__number {
    font-size: 1.4rem;
    top: -20px;
  }

  body {
    line-height: 1.5;
  }

  .ic-menu__baslik {
    font-size: 1.2rem;
  }

  .ic-menu {
    padding-top: 120px;
    padding-bottom: 6px;
  }

  .ic-menu__link {
    font-size: 0.8rem;
  }

  .hikaye-bg {
    height: 100px;
  }

  .sef__resim {
    width: 120px;
    margin-top: -70px;
  }

  .sef__baslik {
    font-size: 1.2rem;
    margin-bottom: 6px;
  }

  .yazi {
    font-size: 0.9rem;
  }

  .hikaye {
    padding: 15px;
  }

  .hikaye--1 {
    margin-top: 30px;
    padding: 15px 0;
  }

  .hikaye--1 .hikaye__card {
    padding: 15px;
  }

  .hikaye--1 .hikaye__gorsel {
    width: 60px;
  }

  .hikaye--2 .hikaye__card {
    padding-top: 60px;
    padding-bottom: 50px;
  }

  .hikaye--2 .hikaye__gorsel {
    width: 100px;
  }

  .hikaye--2 .hikaye__gorsel--2 {
    bottom: -40px;
  }

  .sef__bilgi {
    padding-top: 0;
  }

  .sef-hikaye-alt {
    padding-top: 0;
  }

  .sef-hikaye-yazi:before {
    display: none;
  }

  .hikaye--3 .hikaye__gorsel {
    width: 100px;
    top: -51px;
  }

  .hikaye--3 .hikaye__card {
    padding: 45px 15px 15px;
    font-size: 1rem;
  }

  .quote {
    padding: 30px 15px;
    margin-top: 200px;
    margin-bottom: 0;
  }

  .baslik {
    font-size: 1.2rem;
  }

  .mutfak-slider {
    margin-top: 15px;

    img {
      max-width: 100%;
    }

    .item {
      margin-right: 6px;
    }
  }

  .mutfak-slider__baslik span {
    font-size: 0.8rem;
  }

  .mutfak-slider__baslik:after {
    height: 12px;
  }

  .mutfak-baslik {
    font-size: 1.2rem;
    line-height: 1.5;
  }

  .mutfak-yazi {
    font-size: 0.9rem;
    width: calc(100% - 45px);
    margin-top: 45px;

    &:before {
      height: 140px;
    }
  }

  .hikaye--4 {
    margin-top: 140px;
  }

  .hikaye--4 .hikaye__bg {
    top: -100px;
  }

  .hikaye--4 .hikaye__gorsel {
    width: 70px;
  }

  .hikaye--4 .hikaye__card {
    padding: 60px 15px;
    width: 100%;
  }

  .hikaye-wrapper--hakkimizda {
    margin-top: 15px;

    .mt-5 {
      margin-top: 15px !important;
    }
  }

  .hakkimizda-yazi {
    font-size: 0.9rem;
    padding-left: 0;
    top: 0;
    margin-top: 25px;
    padding-bottom: 15px;
  }

  .hakkimizda {
    width: 100%;
    margin-left: 0;
    margin-top: 15px;

    &__item {
      padding: 15px;
    }

    &__yazi {
      font-size: 0.9rem;
    }
  }

  .hakkimizda__baslik {
    font-size: 1.2rem;
  }

  .hmresim {
    height: 150px;
  }

  .menu-ust .slider-detay__baslik {
    font-size: 1.2rem;
  }

  .menu-ust .slider-detay__yazi {
    font-size: 0.9rem;
  }

  .urunler__baslik {
    font-size: 1.2rem;
  }

  .urun__baslik {
    font-size: 0.8rem;
  }

  .galeri--slider {
    width: 100%;
    white-space: wrap;
    padding-top: 0;
  }

  .urunler {
    width: 100%;
    margin-left: 0;
    padding-left: 15px;
    padding-right: 15px;
  }

  .scroll--galeri .urun {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
    display: block;
    margin-bottom: 10px;
  }

  .mobil-scroll {
    margin-left: 0;
    overflow: auto;
  }

  .cursor {
    display: none;
  }

  .ic-menu__scroll {
    overflow-y: auto;
    overflow-x: scroll;
    white-space: nowrap;
    height: auto;

    &::-webkit-scrollbar {
      height: 4px;
    }

    .ic-menu__link {
      display: inline-block;
      margin-right: 10px;
      margin-top: 0;
    }
  }

  .ic-menu {
    padding-right: 15px;
  }

  .etkinlik--galeri {
    margin-top: 30px !important;
  }

  .ml-sol {
    margin-left: 0;
  }

  .etkinlik-mobil {
    overflow: auto;
    margin-top: 15px !important;

    .urunler__slider {
      white-space: inherit;
    }
  }

  .etkinlik {
    width: 100%;
    margin-right: 0;
    margin-bottom: 15px;

    &__alt {
      padding-right: 15px;
      padding-left: 15px;
      padding-bottom: 45px;
    }

    &__baslik {
      font-size: 1rem;
    }

    &__kisa {
      font-size: 0.9rem;
    }

    &__link {
      bottom: 15px;
      right: 15px;
      font-size: 0.9rem;
    }

    &__tarih {
      width: 80px;
    }

    &__gun {
      font-size: 1.1rem;
    }

    &__saat {
      font-size: 1.1rem;
    }
  }

  .sayfa {
    padding-top: 120px;
  }

  .sayfa__baslik {
    font-size: 1.2rem;
  }

  .mt-5-mobil {
    margin-top: 15px !important;
  }

  .form-yazi strong {
    font-size: 1.2rem;
  }

  .btn {
    padding: 6px 28px !important;
  }

  .geri-btn {
    font-size: 1rem;
  }

  .geri-wrapper {
    padding-left: 18px;
  }

  .harita {
    height: 250px;
  }

  .ic-menu--paket .ic-menu__scroll {
    height: auto;
  }

  .paket-baslik {
    font-size: 1.2rem;
    margin-left: 0;
  }

  .paket--wrapper {
    margin-top: 25px;
    width: 100%;
    padding: 0 15px;
  }

  .sepet-icon {
    top: -6px;
    right: 15px;
    left: auto;
  }

  .sayfa__wrapper {
    margin-left: 0;
    width: 100%;
  }

  .servis__resim {
    height: 160px;
    margin-bottom: 15px;
  }

  .servis__baslik {
    font-size: 1rem;
  }

  .servis__fiyat {
    font-size: 1.1rem;
    top: -2px;
  }

  .servis__currency {
    font-size: 0.9rem;
  }

  .servis__aciklama {
    font-size: 0.8rem;
  }

  .servis {
    margin-bottom: 15px;
    padding-bottom: 15px;
  }

  .servis__butonlar {
    margin-top: 15px;

    .servis__number {
      width: 48%;

      input {
        height: 34px;
        line-height: 34px;
        font-size: 0.8rem;
      }
    }

    .btn-sepet {
      width: 48%;
      margin-top: 0 !important;
    }

    .servis__btn {
      font-size: 0.8rem;
      height: 34px;
      line-height: 34px;
    }
  }

  .btn {
    font-size: 0.8rem !important;
  }

  .sepet-wrapper img {
    width: 120px;
  }

  .icindekiler {
    font-size: 0.9rem;

    span {
      font-size: 0.9rem;
    }
  }

  .sepet-modal {
    .sepet-adetler {
      margin-top: 15px !important;
      font-size: 0.9rem;

      input {
        font-size: 0.8rem;
      }
    }

    .sepet-fiyat {
      width: auto;
      display: inline-block;
      padding-top: 15px;
    }
  }

  .modal-butonlar {
    margin-top: 15px;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .servis__btn {
      height: 34px;
      line-height: 34px;
      width: 48%;
      padding-left: 0;
      padding-right: 0;
      text-align: center;
    }
  }

  .form-control,
  .servis__number input {
    height: 34px;
    line-height: 34px;
  }

  .sepet-urun__resim {
    display: none;
  }

  .sepet-urun__aciklama {
    font-size: 0.8rem;
    padding-left: 0;
    border: none;
    height: auto;
    margin-left: 0;
    width: 100%;
  }

  .sepet-urun {
    width: auto;
    flex-wrap: wrap;
    display: block;
  }

  .sepet-scroll {
    overflow: auto;
  }

  .sepet-scroll .sepet-table {
    width: 100%;
  }

  .sepet-fiyatlar__fiyat {
    display: none;
  }

  .sepet-fiyatlar__input {
    border: none;
    height: 34px;
    padding: 0;
  }

  .sepet-bilgiler {
    display: none;
  }

  .sepet-fiyatlar__tutar {
    font-size: 1rem;
  }

  .sepet-sil {
    font-size: 1rem;
  }

  .sepet-toplam {
    width: 100%;
    padding: 0;
    font-size: 1.1rem;
  }

  .sepet-toplam__baslik {
    border: none;
    padding-left: 0;
  }

  .sepet-table__ust {
    font-size: 1rem;
  }

  .sepet-sayfa {
    padding-top: 120px;

    .sayfa__baslik {
      font-size: 1.2rem !important;
    }

    .ust-baslik {
      margin-left: 15px;
    }

    .sayfa__altyazi {
      font-size: 0.9rem !important;
    }
  }

  .icerik__baslik {
    font-size: 1.2rem;
  }

  .icerik__link,
  .icerik__yazi {
    font-size: 0.9rem;
  }

  .icerik__resim {
    height: 250px;
  }

  .icerik__text {
    margin-top: 40px;
  }

  .etkinlik-ic-sayfa {
    .ic-menu {
      padding-top: 140px;
    }

    .ic-menu--fixed {
      display: none;
    }
  }

  .slider {
    width: calc(100% - 30px);
    left: 15px;

    .image {
      width: 100%;

      img {
        width: 120%;
        max-width: 120%;
      }

      &__text {
        font-size: 1rem;
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    .image__resim:after {
      width: 120%;
      max-width: 120%;
    }
  }

  .dil-linkler {
    position: relative;
    right: auto;
    top: 0;
    margin-bottom: 15px;
  }

  .dil-link {
    margin-left: 0;
    margin-right: 15px;
  }

  .oneri{
    &__resim{
      width: 70px;
    }

    &__baslik{
      margin-bottom: 5px;
    }
  }
}


.popup, .popup-bg{
  display: none;
}
