@media (max-width: 992px) {
  .slider-detay {
    flex-direction: column-reverse;
    flex-wrap: wrap;

    &__text {
      width: 100%;
      text-align: center;
      margin-top: 30px;
    }

    &__resim {
      width: 40%;
      margin: 0 auto;
    }
  }

  .slider-detay__baslik {
    font-size: 1.5rem;
  }

  .slider-detay__resim {
    width: 300px;
    max-width: 100%;
  }

  .slider-detay__yazi {
    font-size: 1.1rem;
  }

  .logo-mobil {
    position: absolute;
    display: block;
    left: 15px;
    top: 15px;
    z-index: 10000;

    img {
      width: 80px;
    }
  }

  .btn {
    font-size: 1rem;
  }

  .site-wrapper {
    flex-wrap: wrap;
  }

  .ic-menu {
    width: 100%;
    padding-left: 15px;
  }

  .ic-wrapper {
    width: 100%;
  }

  .ic-menu--fixed {
    position: relative;
  }

  .ic-menu__ot {
    display: none;
  }

  .urunler__nav {
    width: auto;
    white-space: nowrap;
    overflow-x: scroll;
    padding-bottom: 6px;
  }

  .menu-ust {
    .slider-detay__text {
      width: 100%;
    }
  }

  .ic-menu {
    min-height: auto;
  }

  .icerik {
    padding: 30px;
  }

  .paket-wrapper{
    width: 100%;
  }

  .paket-sayfa{
    width: calc(100% - 60px);
  }

  .servis__resim{
    height: 300px;
    margin-bottom: 60px;
  }

  .servis-liste{
    height: auto;
    overflow: auto;
  }

  .servis__butonlar{
    justify-content: space-between;
    .servis__number{
      width: 30%;
    }
    .servis__btn{
      width: 30%;
    }
  }

    .sepet-modal{
      max-height: 90vh;
      overflow-y: scroll;
  }

  .modal-close{
    top: 5px;
    right: 5px;
    background: #828282;
    height: 37px;
    width: 37px;
    line-height: 37px;
    border-radius: 50%;
    z-index: 9;
  }

  .dil-linkler{
    right: 30px;
  }

  .giris-logo{
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}
