.sepet-table {
  color: #002F3B;
  border: 1px solid #E9E9E9;
  border-radius: 0.25rem;
}

.sepet-table__ust {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: $ff_dinMedium;
  font-size: 1.1rem;
  background: #E9E9E9;
  padding: 15px;
}

.sepet-bilgiler {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 60px;
  width: 360px;
}

.sepet-tr {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
  padding: 15px;
  border-bottom: 1px solid #dfdfdf;

  &:last-child {
    border: none;
  }
}

.sepet-urun {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: calc(100% - 400px);

  &__resim {
    margin-right: 25px;
  }

  &__baslik {
    font-family: $ff_dinMedium;
    color: $renk_yesil;
  }

  &__aciklama {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    font-family: $ff_dinMedium;
    padding-left: 30px;
    margin-left: 30px;
    border-left: 1px solid #dfdfdf;
    height: 50px;
  }
}

.sepet-fiyatlar {
  width: 50%;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: $ff_dinMedium;
  margin-left: 30px;
  width: 340px;

  &__fiyat {
    position: relative;
    left: -15px;
  }

  &__input {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    position: relative;
    left: -16px;
    padding: 0 40px;
    border-left: 1px solid #dfdfdf;
    border-right: 1px solid #dfdfdf;
    height: 50px;

    input {
      border: 1px solid #dfdfdf;
      outline: none;
      text-align: center;
      width: 40px;
      height: 40px;
    }
  }

  &__tutar {
    font-family: $ff_dinBold;
    font-size: 1.1rem;
    color: $renk_yesil;
  }
}

.sepet-sil {
  font-size: 1.3rem;
  color: #EB5757;
  @include efekt(color);

  &:hover {
    color: #CD0000;
  }
}

.sepet-toplam {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: $ff_dinBold;
  font-size: 1.3rem;
  padding-right: 30px;
  margin-left: auto;

  &__baslik {
    display: inline-block;
    padding: 0 25px;
    margin-right: 45px;
    border-left: 1px solid #dfdfdf;
    border-right: 1px solid #dfdfdf;
  }

  &__son {
    color: $renk_yesil;
  }
}

.diger-urun {
  border-right: 1px solid #dfdfdf;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  justify-content: center;
  align-items: center;

  &__resim {}

  &__bilgi {
    padding-left: 15px;
  }

  &__baslik {
    display: block;
    font-family: $ff_dinBold;
  }

  &__fiyat {
    display: block;
    color: $renk_yesil;
    font-family: $ff_dinMedium;
  }

  &__sepet {
    padding: 2px 12px 4px;
    border-radius: 32px;
    display: block;
    margin-top: 10px;
    font-size: 0.9rem;
    font-family: $ff_dinMedium;
    background: $renk_mavi;
    color: #fff;
    text-decoration: none;
    @extend .efekt;

    &:Hover {
      background: $renk_yesil;
    }

    i {
      font-size: 0.7rem;
    }
  }
}

.diger-urunler {
  .slick-prev {
    left: 0;
    z-index: 3;
    border-radius: 50%;
    padding: 2px;

    &:before {
      color: #828282;
    }
  }

  .slick-next{
    right: 0;
    z-index: 3;
    border-radius: 50%;

    &:before {
      color: #828282;
    }
  }
}

.odeme-table {
  border: 1px solid #dfdfdf;
  border-radius: .25rem;
}

.odeme {
  padding: 15px;
  border-bottom: 1px solid #dfdfdf;
  font-family: $ff_dinBold;
  font-size: .9rem;
  position: relative;
  padding-right: 60px;

  &:last-child{
    border: none;
  }

  label{
    cursor: pointer;
  }

  input{
    position: relative;
    top: 2px;
  }
}

.odeme-resim{
  @extend .dikeyAbsolute;
  right: 15px;
}

.diger-odeme{
  display: none;
  .odeme{
    padding-left: 30px;
  }
}

.odeme-aciklama{
  color: #a9a9a9;
  font-size: .9rem;
  font-family: $ff_dinMedium;
  display: inline-block;
  margin-left: 15px;
}

.odeme-alt{
  .custom-control-label{
    font-size: .9rem;
    font-family: $ff_dinMedium;
  }
}

.odeme-alt-btn{
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
@media(max-width:1400px) {
  .sepet-urun__aciklama {
    font-size: 0.9rem;
    height: auto;
  }
}

@media(max-width:1200px) {
  .sepet-scroll {
    overflow-x: scroll;

    .sepet-table {
      width: 1200px;
    }
  }
}

@media(max-width:992px){
  .diger-urun{
    border: none;
  }

  .odeme-alt-btn{
    justify-content: center;
  }

  .odeme-alt-yesil{
    text-align: center;

    img{
      display: block;
      margin: 0 auto;
    }
  }
}

@media(max-width:450px){
  .diger-urun{
    flex-wrap: wrap;
  }
}
