@mixin clearfix() {
  &::after {
    content: '';
    display: block;
    clear: both;
  }
}
@mixin efekt($what: all) {
  transition: $what .25s ease-in-out;
}

.efekt {
  transition: all 0.25s ease-in-out;
}

.bdrs {
  border-radius: 0.25rem;
}

.pos-rel{
  position: relative;
}

.dikeyAbsolute {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.dikeyFlex {
  display: flex;
  align-items: center;
}

.bxs {
  box-shadow: 0 2px 6px $renkss, 0 2px 6px $renkss;
  transition: all 0.2s ease-in-out;

  &:Hover {
    box-shadow: 0 8px 18px $renkss, 0 8px 18px $renkss;
  }
}

.bxsh {
  transition: all 0.2s ease-in-out;

  &:Hover {
    box-shadow: 0 10px 22px $renkss, 0 10px 10px $renkss;
  }
}

.fz9 {
  font-size: 0.9rem;
}

.fz85 {
  font-size: 0.85rem;
}

.fz8 {
  font-size: 0.8rem;
}

.fz7 {
  font-size: 0.7rem;
}

.bgcover {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.fw300 {
  font-weight: 300;
}

.fw400 {
  font-weight: 400;
}

.fw500 {
  font-weight: 500;
}

.fw600 {
  font-weight: 600;
}

.fw700 {
  font-weight: 700;
}

.fw800 {
  font-weight: 800;
}

.mb15{
  margin-bottom: 15px;
}

.mb30{
  margin-bottom: 30px;
}

.mt15{
  margin-top: 15px;
}

.mt30{
  margin-top: 30px;
}
