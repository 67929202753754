@import 'reset', 'font', 'colors', 'mixin', 'global', 'hamburgers', 'bootstrap-grid', 'slick', 'forms', 'fontawesome';

.btn-mobil,
.mobil {
  display: none;
}

.site-navigation {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  padding: 30px 0;
  text-align: center;
  width: 260px;
  height: 100%;
  z-index: 2;
}

.logo-mobil {
  display: none;
}

.menu {
  &__link {
    display: block;
    position: relative;
    font-family: $ff_dinMedium;
    font-size: 1.2rem;
    color: $renk_mavi;
    padding: 6px 0;
    margin: 6px auto;
    text-align: center;
    width: 80%;
    text-decoration: none;
    @include efekt(color);

    &:hover {
      color: $renk_yesil;
    }
  }
}

.rezervation {
  bottom: 60px;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding-left: 45px;
  padding-right: 45px;
  font-weight: 600;
  border-color: $renk_mavi;
  color: $renk_mavi;

  &:hover{
    border-color: $renk_yesil;
  }
}

.site {
  position: relative;
  background: #f4f4f4;
  padding-left: 260px;
  overflow-x: hidden;
  min-height: 100vh;

  &--nopadding {
    padding: 0;
  }
}

.navigation {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  background: $renk_mavi;
  box-shadow: 0 200px 0 0 $renk_mavi;
  padding: 15px;
  text-align: center;
  width: 70px;
  height: 100%;
  z-index: 4;

  &__link {
    @extend .efekt;
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    color: $renk_yesil;
    margin: 15px auto;
    border: 1px solid rgba($renk_yesil, .5);
    border-radius: 50%;
    width: 36px;
    height: 36px;
    text-decoration: none;

    &:hover {
      background: $renk_yesil;
      color: #fff;
      border-color: $renk_yesil;
    }
  }

  &__arrow {
    @extend .efekt;
    position: absolute;
    bottom: 30px;
    left: 0;
    right: 0;
    margin: 0 auto;
    transform: scale(1);

    path {
      @include efekt(stroke);
    }

    &:hover {
      svg {
        path {
          stroke: #A8D964;
        }
      }
    }
  }
}

.progress-wrapper {
  display: block;
  position: absolute;
  bottom: 30px;
  left: 330px;
  right: 90px;
  width: calc(100% - 470px);
}

.progress {
  @extend .bdrs;
  position: relative;
  background-color: none;
  background-image: linear-gradient(to right, $renk_yesil, $renk_yesil);
  background-repeat: no-repeat;
  background-size: 0 100%;
  overflow: hidden;
  height: 6px;
  transition: background-size 0.4s ease-in-out;
  z-index: 1;

  &--bg {
    @extend .dikeyAbsolute;
    left: 0;
    right: 0;
    background: $renksb-4;
    width: 100%;
    height: 1px;
    z-index: 0;
  }
}

.slider {
  position: absolute;
  bottom: 90px;
  left: 260px;
  right: 0;
  width: calc(100% - 330px);

  .slick-list {
    padding-top: 30px;
  }
}

.slider-for {
  .slick-slide {
    min-height: 100vh;
  }
}

.slider-detay {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  padding: 30px 90px 90px;

  &__text {
    width: 60%;
  }

  &__baslik {
    display: block;
    font-family: $ff_dinBold;
    font-size: 4rem;
    margin-bottom: 30px;
    line-height: 1.3;
  }

  &__yazi {
    font-family: $ff_dinLight;
    font-size: 1.4rem;
    color: $renk_acik;
    display: block;
  }

  &__resim {
    width: 45%;
  }
}

.image {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin: 0 auto;
  width: 280px;

  &__number {
    position: absolute;
    top: -30px;
    left: 6px;
    font-size: 2rem;
    color: #fff;
    z-index: 2;
  }

  &__text {
    font-size: 1rem;
    color: #fff;
    color: $renk_acik;
    margin-left: 15px;
    text-align: left;
  }

  &__resim {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      background: $renkss-4;
      width: 100%;
      height: 100%;
      @include efekt(background-color);
    }
  }

  &:hover {
    cursor: pointer;

    .image__resim {
      &:after {
        background: rgba(0,0,0,0);
      }
    }
  }
}

.slider-arrow {
  @extend .dikeyAbsolute;
  font-size: 1.4rem;
  color: $renksb-4;
  @include efekt(color);

  &:hover {
    color: #fff;
  }
}

.prev {
  left: -30px;
}

.next {
  right: -30px;
}

.paket {
  position: absolute;
  top: 30px;
  right: 30px;
  z-index: 10001;
}

.sepet {
  display: none;
  position: fixed;
  top: 0;
  left: 260px;
  background: #fff;
  height: 100%;
  max-width: calc(100% - 330px);
  z-index: 1000;

  &--active {
    display: block !important;
  }
}

.geri-wrapper {
  background: #EFF3EC;
  padding: 30px 30px 30px 60px;
}

.geri-btn {
  font-family: $ff_dinMedium;
  color: $renk_mavi;
  text-decoration: none;
  @include efekt(color);

  svg path {
    @include efekt(fill);
  }

  &:hover {
    color: $renk_yesil;

    svg {
      path {
        fill: $renk_yesil;
      }
    }
  }

  span {
    display: inline-block;
    margin-left: 6px;
  }
}

.hikaye-bg {
  height: 280px;
}

.site-wrapper {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  justify-content: space-between;
}

.ic-menu {
  position: relative;
  background-color: $renk_mavi;
  color: #fff;
  padding: 160px 60px 30px;
  width: 320px;
  min-height: 100vh;
  z-index: 9999;

  &--fixed {
    position: fixed;
    z-index: 3;
  }

  &__baslik {
    display: block;
    font-family: $ff_dinBold;
    font-size: 3rem;
    line-height: 1.4;
    width: 200px;
    margin-bottom: 10px;
  }

  &__altbaslik {
    display: block;
    margin-top: -10px;
    width: 200px;
  }

  p {
    display: block;
    font-family: $ff_dinLight;
    font-size: 0.9rem;
    color: $renk_acik;
    width: 200px;
  }

  &__link {
    display: block;
    font-size: 1rem;
    color: $renk_acik;
    padding: 6px 0;
    margin: 4px 0;
    text-decoration: none;
    @include efekt(color);

    &--tarih {
      display: block;
      font-family: $ff_dinBold;
      font-size: 1.5rem;
      margin-top: 15px;
    }

    &.active {
      color: $renk_yesil;
    }

    &:hover {
      color: $renk_yesil;
    }
  }

  &__ot {
    display: none !important;
    position: fixed;
    bottom: 0;
    margin-top: 30px;
    margin-left: -30px;
    width: 260px;
    z-index: 0;
  }

  &__scroll {
    overflow-y: scroll;
    height: 300px;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba($renk_yesil, .4);
      border-radius: 6px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: $renk_yesil;
    }
  }

  &--paket {
    .ic-menu__ot {
      display: none;
    }

    .ic-menu__scroll {
      height: calc(100vh - 420px);
    }
  }
}

.ic-wrapper {
  width: calc(100% - 320px);

  &--genis{
    width: 100%;
  }
}

.hikaye-wrapper {
  position: relative;
  background: #EFF3EC;
  padding: 30px 60px 0;
}

.sef {
  display: flex;
  justify-content: space-between;

  &__resim {
    margin-top: -160px;
    width: 36%;
  }

  &__bilgi {
    padding-top: 80px;
    padding-left: 30px;
    width: 70%;
  }

  &__baslik {
    font-family: $ff_dinBold;
    font-size: 3rem;
  }

  &__yazi {}
}

.sefresim--1 {
  display: block;
  position: relative;
  top: -30px;
  margin-left: auto;
}

.hikaye {
  position: relative;
  padding: 90px 0;

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 70%;
    height: 100%;
  }

  &__card {
    position: relative;
    background: #fff;
    padding: 90px 180px 90px 90px;
    margin-left: auto;
    width: 70%;
  }

  &__gorsel {
    position: absolute;
    top: -180px;
    right: 30px;
  }

  &--1 {
    margin-top: 120px;
    margin-bottom: 60px;
  }
}

.hikaye--2 {
  .hikaye {
    &__card {
      padding-top: 140px;
      padding-bottom: 140px;
      margin-left: 10%;
      width: 60%;

      p {
        position: relative;
        left: -40%;
      }
    }

    &__gorsel {
      top: -30px;
      left: 30px;

      &--2 {
        @extend .dikeyAbsolute;
        top: 50%;
        left: auto;
        right: -45%;
      }
    }
  }
}

.sef-hikaye-alt {
  position: relative;
  padding-top: 90px;
}

.sef-hikaye-bg {
  position: absolute;
  top: 0;
  left: -60px;
  width: calc(100% + 120px);
  height: 100%;
  z-index: 0;
}

.sef-hikaye-yazi {
  position: relative;
  color: #fff;

  .row {
    position: relative;
    bottom: -60px;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background: $renk_acik;
    width: 120px;
    height: 1px;
  }
}

.hikaye--3 {
  position: relative;
  bottom: -150px;

  .hikaye {
    &__bg {
      left: auto;
      right: 0;
    }

    &__card {
      padding: 120px 60px 60px;
      margin-left: 0;
      margin-right: auto;
      width: 50%;

      p {
        font-family: $ff_dinMedium;
        font-size: 1.3rem;
      }
    }

    &__gorsel {
      left: 0;
    }
  }
}

.quote {
  position: relative;
  font-family: $ff_dinMedium;
  font-size: 1.6rem;
  padding: 75px;
  margin: 240px auto 120px;
  text-align: center;
  width: 80%;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background-image: url("../../dist/img/quote.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 40px;
    height: 40px;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    background-image: url("../../dist/img/quote.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 40px;
    height: 40px;
    transform: rotate(180deg);
  }
}

.mutfak-slider {
  padding-left: 60px;
  margin-top: 75px;
  width: calc(100% + 60px);

  .item {
    position: relative;
    margin-right: 15px;
  }

  &__baslik {
    position: absolute;
    bottom: 15px;
    left: 15px;
    font-family: $ff_dinMedium;
    font-size: 1.3rem;
    padding: 0 6px;

    span {
      position: relative;
      z-index: 1;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      background: #fff;
      width: 100%;
      height: 17px;
      z-index: 0;
    }
  }
}

.baslik-bg {
  position: absolute;
  top: 130px;
  left: 60px;
  background-image: url("../../dist/img/hikayesvg.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 380px;
}

.hikaye--4 {
  margin-top: 400px;

  .hikaye__bg {
    top: -260px;
    width: 400px;
    height: 500px;
  }

  .hikaye__card {
    padding: 140px 90px 120px;
    margin-right: auto;
    width: 60%;
  }

  .hikaye__gorsel {
    top: -70px;
    right: -45px;
    width: 170px;
  }
}

.mutfak-ust {
  position: relative;
  margin-top: 60px;
}

.mutfak-baslik {
  display: block;
  font-family: $ff_dinBlack;
  font-size: 4.2rem;
  line-height: 1.2;
}

.mutfak-yazi {
  position: absolute;
  bottom: -290px;
  left: 500px;
  font-family: $ff_dinMedium;
  width: 400px;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: -30px;
    background: $renk_yesil;
    width: 2px;
    height: 300px;
  }
}

.hakkimizda-yazi {
  display: block;
  position: relative;
  top: -120px;
  font-family: $ff_dinMedium;
  padding-left: 30px;
  width: 40%;
}

.hakkimizda {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-left: -60px;
  width: calc(100% + 120px);

  &--2 {}

  &__item {
    background-color: #fff;
    padding: 140px;
    width: 50%;
  }

  &__baslik {
    display: block;
    font-family: $ff_dinBold;
    font-size: 1.6rem;
    color: $renk_yesil;
    margin-bottom: 30px;
  }

  &__yazi {

  }
}

.menu-ust {
  justify-content: flex-start;

  .slider-detay__baslik {
    font-size: 2.7rem;
  }

  .slider-detay__yazi {
    font-size: 1.1rem;
  }

  .slider-detay__resim {
    width: 100% !important;
  }

  .slider-detay__text {
    width: 44%;
  }

  &__resim {
    width: 44%;
  }
}

.urunler {
  padding-bottom: 15px;
  margin-left: 90px;
  width: calc(100% - 150px);

  &__baslik {
    display: block;
    font-family: $ff_dinBold;
    font-size: 2.5rem;
    color: #fff;
    margin-bottom: 15px;
  }

  &__altbaslik {
    color: $renk_acik;
  }

  &__nav {
    display: block;
    margin-bottom: 30px;
  }

  &__link {
    color: #fff;
    @include efekt(color);
    text-decoration: none;
    display: inline-block;
    margin-right: 25px;
    font-size: 1rem;

    &.active,
    &:hover {
      color: $renk_yesil;
    }
  }

  &__scroll {
    cursor: none !important;
    margin-left: -15px;
    overflow-x: scroll;

    &:hover {
      #cursor {
        opacity: 1;
        transform: scale(1);
        z-index: 2;
      }
    }

    &::-webkit-scrollbar {
      height: 4px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba($renk_yesil, .4);
      border-radius: 6px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: $renk_yesil;
    }
  }

  &__slider {
    cursor: none !important;
    width: auto;
    white-space: nowrap;
  }
}

.urun {
  display: inline-block;
  position: relative;
  cursor: none !important;
  margin-right: 30px;
  width: 400px;

  &:first-child {
    margin-left: 15px;
  }

  &__baslik {
    position: absolute;
    top: 15px;
    left: -15px;
    font-size: 1rem;
    background: $renk_yesil;
    color: #fff;
    padding: 2px 8px;
  }

  &--galeri {
    img {
      @extend .efekt;

      &:hover {
        transform: scale(1.05);
      }
    }
  }
}

#cursor {
  @extend .efekt;
  position: absolute;
  opacity: 0;
  transform: scale(0);
  transition: transform 0.3s ease-in-out;
}

.menu-bg {
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover;
}

.scroll--galeri {
  padding-left: 0;
  margin-left: 0;
  width: 100%;

  .urun {
    width: 700px;
  }
}

.etkinlik {
  @extend .efekt;
  display: inline-block;
  background: #fff;
  margin-right: 30px;
  border: 1px solid #dfdfdf;
  width: 600px;

  &:hover {
    box-shadow: 0 0 0 4px rgba($renk_yesil, .3);
    border-color: $renk_yesil;
  }

  a {
    text-decoration: none;
  }

  &__bg {
    @extend .bgcover;
    margin-right: 30px;
    width: 100%;
    height: 400px;
  }

  &__alt {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: flex-end;
    position: relative;
    padding: 30px 30px 90px;
    margin-top: -60px;
  }

  &__tarih {
    font-family: $ff_dinBold;
    background: $renk_mavi;
    color: #fff;
    padding: 15px;
    line-height: 1.2;
    text-align: center;
    width: 120px;
  }

  &__gun {
    display: block;
    font-size: 1.4rem;
    padding-bottom: 6px;
    margin-bottom: 6px;
    border-bottom: 1px solid $renksb;
  }

  &__saat {
    font-size: 1.6rem;
  }

  &__text {
    margin-left: 15px;
    white-space: normal;
  }

  &__baslik {
    display: block;
    font-family: $ff_dinBold;
    font-size: 1.2rem;
    color: $renk_mavi;
  }

  &__kisa {
    font-size: 1rem;
    color: $renk_mavi;
  }

  &__link {
    color: $renk_yesil;
    @include efekt(color);
    position: absolute;
    right: 30px;
    bottom: 30px;
    text-decoration: underline;
    font-family: $ff_dinBold;

    &:hover {
      color: $renk_mavi;
    }
  }
}

.etkinlik--galeri {
  margin-left: 0;
  width: 100%;

  .urunler__scroll {
    padding: 6px;
    margin-left: 0;
  }

  .urunler__slider {
    padding-bottom: 15px;
  }
}

.ml-sol {
  margin-left: 30px;
}

.icerik {
  padding: 0 100px 0 70px;

  &__resim {
    @extend .bgcover;
    margin-bottom: 30px;
    height: 430px;
  }

  &__alt {
    display: flex;
    align-items: flex-start;
    position: relative;
  }

  &__tarih {}

  &__text {
    margin-left: 30px;
  }

  &__baslik {
    display: block;
    font-family: $ff_dinBold;
    font-size: 2.3rem;
    margin-bottom: 30px;
    line-height: 1.2;
  }

  &__yazi {
    line-height: 1.6;
  }

  &__link {
    font-size: 1.2rem;
    color: $renk_yesil;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.sayfa {
  position: relative;
  padding-top: 160px;
  width: 100%;

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 350px;
    z-index: 0;
  }

  &__wrapper {
    @extend .bdrs;
    position: relative;
    background: #fff;
    padding: 30px;
    margin: 0 auto;
    border: 1px solid $renk_acik;
    width: calc(100% - 60px);
    z-index: 3;
  }

  &__baslik {
    display: block;
    font-family: $ff_dinBold;
    font-size: 2rem;
    line-height: 1.2;
  }

  &__altyazi {
    font-family: $ff_dinMedium;
    font-size: 0.9rem;
  }
}

.iletisim-box {
  display: block;
  font-family: $ff_dinMedium;
  width: 100%;

  &__label {
    display: block;
    font-size: 0.9rem;
    color: $renk_yesil;
    line-height: 1;
  }

  a {
    color: $renk_mavi;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.breadcrumb {
  position: relative;
  font-size: 1rem;
  color: #fff;
  margin-bottom: 15px;
  margin-left: 30px;
  z-index: 1;

  &__link {
    display: inline-block;
    color: #fff;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &__ayrac {
    display: inline-block;
    margin: 0 4px;
  }
}

.form-control {
  @extend .bdrs;
  @extend .efekt;
  display: block;
  font-size: 1rem;
  background: #fff;
  padding: 0 8px;
  border: 1px solid #E0E0E0;
  line-height: 44px;
  outline: transparent;
  width: 100%;
  height: 44px;
  appearance: none;
  -webkit-appearance: none;

  &:focus,
  &:hover {
    box-shadow: 0 0 0 3px rgba($renk_yesil, .3);
    border-color: $renk_yesil;
  }
}

.form-label {
  display: block;
  font-family: $ff_dinMedium;
  font-size: 0.9rem;
  margin-bottom: 8px;
}

.form-yazi {
  font-family: $ff_dinMedium;

  strong {
    font-family: $ff_dinBold;
    font-size: 1.5rem;
    font-weight: normal;
  }
}

.galeri--slider {
  padding: 30px 0;
}

.paket--wrapper {
  margin-top: 120px;
}

.paket-baslik {
  margin-left: 30px;
  font-size: 1.8rem;
  color: $renk_yesil;
  font-family: $ff_dinBold;
  display: block;
  margin-bottom: 15px;
}

.sepet-icon {
  position: absolute;
  top: -80px;
  left: 30px;
  color: $renk_mavi;
  text-decoration: none;
  font-size: 1.5rem;
}

.sepet-sayi {
  font-size: 1rem;
  font-family: $ff_dinMedium;
  margin-left: 8px;
}

.paket-wrapper {
  width: calc(100% - 120px);
}

.paket-scroll {
  overflow-y: scroll;
}

.servis-wrapper {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  justify-content: space-between;
}

.servis-liste {
  height: 600px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba($renk_yesil, .4);
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $renk_yesil;
  }
}

.servis {
  position: relative;
  border-bottom: 1px solid $renk_acik;
  padding-bottom: 30px;
  margin-bottom: 30px;
  margin-right: 30px;

  &__resim {
    height: 100%;
  }

  &__baslik {
    font-size: 1.7rem;
    display: block;
    font-family: $ff_dinMedium;
    width: calc(100% - 55px);
    line-height: 1.4;
    margin-bottom: 7px;
  }

  &__aciklama {
    font-size: 1rem;
    width: 270px;
    display: block;
    color: #828282;
  }

  &__fiyat {
    position: absolute;
    top: 0;
    right: 30px;
    font-size: 1.9rem;
    font-family: $ff_dinBold;
  }

  &__currency {
    font-family: $ff_dinMedium;
    font-size: 1.3rem;
  }

  &__butonlar {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__number {
    width: 110px;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #E0E0E0;
    border-radius: 32px;

    a {
      text-decoration: none;
      color: #828282;
      display: inline-block;
      padding: 0 8px;
      font-size: 0.8rem;
      @include efekt(color);

      &:hover {
        color: $renk_yesil;
      }
    }

    input {
      width: 40px;
      font-size: 1rem;
      height: 38px;
      line-height: 38px;
      border: none;
      text-align: center;
      color: $renk_mavi;
      font-family: $ff_dinBold;
    }
  }

  &__btn {
    height: 38px;
    line-height: 38px;
    border-radius: 32px;
    text-align: center;
    box-shadow: inset 0 0 0 1px $renk_yesil;
    padding: 0 18px;
    font-size: 0.9rem;
    color: $renk_yesil;
    text-decoration: none;
    font-family: $ff_dinMedium;
    @extend .efekt;

    &:hover {
      background: $renk_mavi;
      box-shadow: inset 0 0 0 1px $renk_mavi;
      color: #fff;
    }
  }

  &__btn--yesil {
    background: $renk_yesil;
    color: #fff;
    box-shadow: none;
  }
}

.paket-sayfa{
  width: 100%;
  margin-left: 30px;
}

.modal-bg{
  z-index: 10002;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: $renkss-4;
  display: none;
}

.sepet-modal{
  width: 70%;
  background: #fff;
  padding: 30px;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 10003;
  display: none;
}



.modal-close{
  color: #fff;
  position: absolute;
  right: -5px;
  top: -60px;
  @include efekt(opacity);

  &:hover{
    opacity: .5;
  }
}

.icindekiler{
  span{
    font-size: 1rem;
    line-height: 1.2;
  }
}

.sepet-adetler {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  strong{
    display: inline-block;
    width: 90px;
  }

  select{
    border-radius: 32px;
  }
}

.modal-butonlar{
  text-align: right;
  margin-top: 90px;

  .servis__btn{
    display: inline-block;
    height: 50px;
    line-height: 50px;
    padding: 0 32px;
  }
}

.sepet-fiyat{
  font-size: 1.6rem;
  color: $renk_yesil;
  font-family: $ff_dinBold;
}
.ust-baslik{
  margin-left: 30px;
  margin-bottom: 15px;
}
.logo-normal{
  display: none;
}

.sol-baslik{
  margin-left: 30px;
}

.saat{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: .9rem;
  width: 100%;
  margin-top: 15px;

  strong{
    font-size: .9rem !important;
  }

  span{
    float: right;
  }
}

.menu-sosyal{
  a{
    margin: 10px;
    border-radius: 50%;
    width: 38px;
    height: 38px;
    display: inline-block;
    line-height: 34px;
    color: $renk_mavi;
    border: 1px solid $renk_mavi;
    @extend .efekt;

    &:hover{
      background: $renk_mavi;
      color: #fff;
    }
  }
}

.giris-logo{
  position: absolute;
  left: 30px;
  top: 30px;
  z-index: 3;
}

.giris{
  position: relative;

  .container-fluid, .row{
    min-height: 100vh;
    height: 100%;
  }

  .row{
    align-items: center;
  }
}

.giris-club{
  padding: 10%;

  .yazi{
    color: #fff;
  }

  .btn{
    color: #fff;
    border-color: #fff;
  }
}

.giris-restorant{
  padding: 10%;
}

.popup-bg{
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99999;
  background: $renkss-2;
  width: 100%;
  height: 100%;
}

.popup{
  width: 1024px;
  max-width: calc(100% - 30px);
  left: 15px;
  right: 15px;
  margin: 0 auto;
  @extend .dikeyAbsolute;
  z-index: 99999;
  background: #fff;
  @extend .bdrs;
  max-height: 90vh;

  img{
    vertical-align: middle;
  }
}

.popup-icerik{
  overflow: hidden;
}

.popup-kapat{
  position: absolute;
  right: -7px;
  top: -7px;
  font-size: 1rem;
  color: #fff;
  background: $renk_yesil;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  text-align: center;
  line-height: 24px;

  &:hover{
    color: $renk_mavi;
  }
}

.dil-link{
  display: inline-block;
  font-size: 1rem;
  color: $renk_yesil;
  text-decoration: none;
  margin-left: 15px;

  &:hover{
    text-decoration: underline;
  }
}

.dil-linkler{
  position: absolute;
  right: -30px;
  top: 15px;
}

.oneri-wrapper{
  border: 1px solid $renk_cizgi;
  @extend .bdrs;
  padding: 15px;
}

.oneri-slider{
  position: relative;

  &:after{
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    background: #fff;
    height: 100%;
    width: 15px;
    z-index: 1;
  }

  .slick-arrow{
    z-index: 4;
    &:before{
      color: $renk_mavi;
    }
  }
  .slick-prev{
    left: 0;
  }

  .slick-next{
    right: 0;
  }
}

.oneri {
  display: flex !important;
  justify-content: center;
  align-items: center;
  border-right: 1px solid $renk_cizgi;

	&__resim {

	}

	&__detay {
    padding-left: 15px;
    position: relative;
	}

	&__baslik {
    color: $renk_mavi;
    font-weight: 600;
    font-size: .9rem;
    display: block;
    margin-bottom: 30px;
	}

	&__fiyat {
    color: $renk_yesil;
	}

}


@import 'style1200', 'style992', 'style768', 'sepet', 'style576';
