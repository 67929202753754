@charset "UTF-8";
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline; }

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none; }

table {
  border-spacing: 0; }

body {
  line-height: 1; }

hr {
  display: block;
  width: 100%; }

@font-face {
  font-family: 'dinMedium';
  src: url("../../dist/font/subset-DINMediumTr.woff2") format("woff2"), url("../../dist/font/subset-DINMediumTr.woff") format("woff"), url("../../dist/font/subset-DINMediumTr.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'dinLight';
  src: url("../../dist/font/subset-DINTLightTr.woff2") format("woff2"), url("../../dist/font/subset-DINTLightTr.woff") format("woff"), url("../../dist/font/subset-DINTLightTr.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'dinBlack';
  src: url("../../dist/font/subset-DINBlackTr.woff2") format("woff2"), url("../../dist/font/subset-DINBlackTr.woff") format("woff"), url("../../dist/font/subset-DINBlackTr.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'din';
  src: url("../../dist/font/subset-DINTr.woff2") format("woff2"), url("../../dist/font/subset-DINTr.woff") format("woff"), url("../../dist/font/subset-DINTr.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'dinBold';
  src: url("../../dist/font/subset-DINTBoldTr.woff2") format("woff2"), url("../../dist/font/subset-DINTBoldTr.woff") format("woff"), url("../../dist/font/subset-DINTBoldTr.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

.renk--mavi {
  color: #002F3B !important; }

.renk--yesil {
  color: #4DA6CC !important; }

.efekt, .btn, .navigation__link, .navigation__arrow, .urun--galeri img, #cursor, .etkinlik, .form-control, .servis__btn, .menu-sosyal a, .diger-urun__sepet {
  transition: all 0.25s ease-in-out; }

.bdrs, .progress, .sayfa__wrapper, .form-control, .popup, .oneri-wrapper {
  border-radius: 0.25rem; }

.pos-rel {
  position: relative; }

.dikeyAbsolute, .progress--bg, .slider-arrow, .hikaye--2 .hikaye__gorsel--2, .popup, .odeme-resim {
  position: absolute;
  top: 50%;
  transform: translateY(-50%); }

.dikeyFlex {
  display: flex;
  align-items: center; }

.bxs {
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1), 0 2px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease-in-out; }
  .bxs:Hover {
    box-shadow: 0 8px 18px rgba(0, 0, 0, 0.1), 0 8px 18px rgba(0, 0, 0, 0.1); }

.bxsh {
  transition: all 0.2s ease-in-out; }
  .bxsh:Hover {
    box-shadow: 0 10px 22px rgba(0, 0, 0, 0.1), 0 10px 10px rgba(0, 0, 0, 0.1); }

.fz9 {
  font-size: 0.9rem; }

.fz85 {
  font-size: 0.85rem; }

.fz8 {
  font-size: 0.8rem; }

.fz7 {
  font-size: 0.7rem; }

.bgcover, .etkinlik__bg, .icerik__resim {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; }

.fw300 {
  font-weight: 300; }

.fw400 {
  font-weight: 400; }

.fw500 {
  font-weight: 500; }

.fw600 {
  font-weight: 600; }

.fw700 {
  font-weight: 700; }

.fw800 {
  font-weight: 800; }

.mb15 {
  margin-bottom: 15px; }

.mb30 {
  margin-bottom: 30px; }

.mt15 {
  margin-top: 15px; }

.mt30 {
  margin-top: 30px; }

html {
  box-sizing: border-box;
  font-size: 100%;
  scroll-behavior: smooth;
  min-height: 100vh;
  min-height: -webkit-fill-available; }

*,
*:after,
*:before {
  box-sizing: inherit; }

body {
  animation: fadeIn 0.5s;
  font-family: "din", sans-serif;
  font-size: 18px;
  color: #002F3B;
  line-height: 1.8;
  height: -webkit-fill-available; }

button,
input,
select,
textarea {
  font-family: "din", sans-serif; }

.noscroll {
  overflow: hidden; }

.tab-panel {
  display: none; }

.tab-active {
  display: block;
  animation: fadeIn 0.5s; }

.tabcontent {
  animation: fadeIn 0.5s; }

.fadeIn {
  animation: fadeIn 0.5s; }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.slick-slide {
  outline: none !important; }

.btn {
  display: inline-block;
  font-family: "Roboto Mono", monospace;
  color: #4DA6CC;
  padding: 6px 20px;
  border: 1px solid #4DA6CC;
  text-decoration: none;
  cursor: pointer; }
  .btn:hover {
    background: #4DA6CC;
    color: #fff; }
  .btn--yesilbg {
    background: #4DA6CC;
    color: #fff; }
    .btn--yesilbg:hover {
      background: #002F3B; }
  .btn--radius {
    background: #002F3B;
    color: #fff;
    border-radius: 32px;
    border-color: #002F3B;
    font-size: 1rem;
    font-family: "dinMedium", sans-serif;
    letter-spacing: 1px;
    padding: 10px 32px; }
    .btn--radius:hover {
      border-color: #4DA6CC; }
  .btn--siparis {
    font-family: "dinMedium", sans-serif;
    border-radius: 32px;
    padding: 6px 34px;
    font-size: 1rem; }
    .btn--siparis:hover {
      border-color: #002F3B; }

.slide-rotate-hor-top {
  animation: slide-rotate-hor-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) reverse both;
  -webkit-animation: slide-rotate-hor-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) reverse both; }

@-webkit-keyframes slide-rotate-hor-top {
  0% {
    -webkit-transform: translateY(0) rotateX(0deg);
    transform: translateY(0) rotateX(0deg); }
  100% {
    -webkit-transform: translateY(-150px) rotateX(-90deg);
    transform: translateY(-150px) rotateX(-90deg); } }

@keyframes slide-rotate-hor-top {
  0% {
    -webkit-transform: translateY(0) rotateX(0deg);
    transform: translateY(0) rotateX(0deg); }
  100% {
    -webkit-transform: translateY(-150px) rotateX(-90deg);
    transform: translateY(-150px) rotateX(-90deg); } }

.transition-fade {
  opacity: 1;
  transition: 0.4s; }

html.is-animating .transition-fade {
  opacity: 0; }

.baslik {
  display: inline-block;
  font-family: "dinMedium", sans-serif;
  font-size: 3rem; }

img {
  max-width: 100%; }

.navigation-animasyon {
  animation: navigasyon ease-in-out 1s;
  animation-fill-mode: forwards; }

.navigation-animasyon--reverse {
  animation: navigasyon2 ease-in-out 1s;
  animation-fill-mode: forwards; }

@keyframes navigasyon {
  0% {
    transform: scale(1) rotate(0deg); }
  50% {
    transform: scale(0.5) rotate(0deg); }
  100% {
    transform: scale(1) rotateX(180deg); } }

@keyframes navigasyon2 {
  0% {
    transform: scale(1) rotateX(180deg); }
  50% {
    transform: scale(0.5) rotate(0deg); }
  100% {
    transform: scale(1) rotate(0); } }

hr {
  background: #E0E0E0;
  border: none;
  height: 1px; }

.hamburger {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }
  .hamburger:hover {
    opacity: 0.7; }

.hamburger-box {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px; }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 40px;
    height: 4px;
    background-color: #4DA6CC;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
  .hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block; }
  .hamburger-inner::before {
    top: -10px; }
  .hamburger-inner::after {
    bottom: -10px; }

.hamburger--3dx .hamburger-box {
  perspective: 80px; }

.hamburger--3dx .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dx .hamburger-inner::before, .hamburger--3dx .hamburger-inner::after {
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dx.is-active .hamburger-inner {
  background-color: transparent;
  transform: rotateY(180deg); }
  .hamburger--3dx.is-active .hamburger-inner::before {
    transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--3dx.is-active .hamburger-inner::after {
    transform: translate3d(0, -10px, 0) rotate(-45deg); }

@-ms-viewport {
  width: device-width; }

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

*,
::after,
::before {
  box-sizing: inherit; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container {
    max-width: 1340px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0; }

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: none; }

.col-1 {
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%; }

.col-2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%; }

.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%; }

.col-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%; }

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%; }

.col-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%; }

.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%; }

.col-11 {
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%; }

.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%; }

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-sm-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-sm-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-sm-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-sm-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-sm-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-md-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-md-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-md-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-md-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-lg-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-lg-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-lg-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-lg-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; } }

@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none; }
  .col-xl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%; }
  .col-xl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%; }
  .col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%; }
  .col-xl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%; }
  .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%; }
  .col-xl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%; }
  .col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%; }
  .col-xl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%; }
  .col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; } }

.flex-wrap {
  flex-wrap: wrap !important; }

.d-flex {
  display: -ms-flexbox !important;
  display: flex !important; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.justify-content-start {
  -ms-flex-pack: start !important;
  justify-content: flex-start !important; }

.justify-content-end {
  -ms-flex-pack: end !important;
  justify-content: flex-end !important; }

.justify-content-center {
  -ms-flex-pack: center !important;
  justify-content: center !important; }

.justify-content-between {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important; }

.justify-content-around {
  -ms-flex-pack: distribute !important;
  justify-content: space-around !important; }

.align-items-start {
  -ms-flex-align: start !important;
  align-items: flex-start !important; }

.align-items-end {
  -ms-flex-align: end !important;
  align-items: flex-end !important; }

.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff; }

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #80bdff; }

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #b3d7ff;
  border-color: #b3d7ff; }

.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d; }

.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
  cursor: pointer;
  padding-top: 3px; }

.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #adb5bd solid 1px; }

.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #007bff;
  background-color: #007bff; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }

.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem; }

.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out; }

@media screen and (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none; } }

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  -webkit-transform: translateX(0.75rem);
  transform: translateX(0.75rem); }

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 123, 255, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

.custom-select:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(128, 189, 255, 0.5); }

.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none; }

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef; }

.custom-select::-ms-expand {
  opacity: 0; }

.custom-select-sm {
  height: calc(1.8125rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(2.875rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.25rem + 2px);
  margin: 0;
  opacity: 0; }

.custom-file-input:focus ~ .custom-file-label {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef; }

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse"; }

.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: 2.25rem;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

.custom-range:focus {
  outline: none; }

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.custom-range::-moz-focus-outer {
  border: 0; }

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none; }

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none; } }

.custom-range::-webkit-slider-thumb:active {
  background-color: #b3d7ff; }

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem; }

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
  appearance: none; }

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none; } }

.custom-range::-moz-range-thumb:active {
  background-color: #b3d7ff; }

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem; }

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #007bff;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none; }

@media screen and (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none; } }

.custom-range::-ms-thumb:active {
  background-color: #b3d7ff; }

.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem; }

.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem; }

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem; }

.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd; }

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default; }

.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd; }

.custom-range:disabled::-moz-range-track {
  cursor: default; }

.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }

@media screen and (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none; } }

.fa,
.fab,
.fal,
.far,
.fas {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1; }

.fa-long-arrow-alt-down:before {
  content: "\f309"; }

.fa-facebook-f:before {
  content: "\f39e"; }

.fa-times-circle:before {
  content: "\f057"; }

.fa-sign-in-alt:before {
  content: "\f2f6"; }

.fa-map-marked-alt:before {
  content: "\f5a0"; }

.fa-long-arrow-alt-left:before {
  content: "\f30a"; }

.fa-trash-alt:before {
  content: "\f2ed"; }

.fa-linkedin:before {
  content: "\f08c"; }

.fa-long-arrow-alt-right:before {
  content: "\f30b"; }

.fa-long-arrow-alt-up:before {
  content: "\f30c"; }

.fa-times:before {
  content: "\f00d"; }

.fa-eye:before {
  content: "\f06e"; }

.fa-heart:before {
  content: "\f004"; }

.fa-calendar:before {
  content: "\f133"; }

.fa-angle-double-down:before {
  content: "\f103"; }

.fa-file-alt:before {
  content: "\f15c"; }

.fa-map-marker-alt:before {
  content: "\f3c5"; }

.fa-angle-double-left:before {
  content: "\f100"; }

.fa-angle-double-right:before {
  content: "\f101"; }

.fa-angle-double-up:before {
  content: "\f102"; }

.fa-shopping-cart:before {
  content: "\f07a"; }

.fa-phone:before {
  content: "\f095"; }

.fa-map:before {
  content: "\f279"; }

.fa-envelope:before {
  content: "\f0e0"; }

.fa-search:before {
  content: "\f002"; }

.fa-user:before {
  content: "\f007"; }

.fa-bars:before {
  content: "\f0c9"; }

.fa-angle-down:before {
  content: "\f107"; }

.fa-angle-left:before {
  content: "\f104"; }

.fa-angle-right:before {
  content: "\f105"; }

.fa-angle-up:before {
  content: "\f106"; }

.fa-th:before {
  content: "\f00a"; }

.fa-ellipsis-h:before {
  content: "\f141"; }

.fa-ellipsis-v:before {
  content: "\f142"; }

.fa-grip-horizontal:before {
  content: "\f58d"; }

.fa-comment:before {
  content: "\f075"; }

.fa-edit:before {
  content: "\f044"; }

.fa-credit-card:before {
  content: "\f09d"; }

.fa-address-card:before {
  content: "\f2bb"; }

.fa-chevron-circle-down:before {
  content: "\f13a"; }

.fa-chevron-circle-left:before {
  content: "\f137"; }

.fa-chevron-circle-right:before {
  content: "\f138"; }

.fa-chevron-circle-up:before {
  content: "\f139"; }

.fa-chevron-down:before {
  content: "\f078"; }

.fa-chevron-left:before {
  content: "\f053"; }

.fa-chevron-right:before {
  content: "\f054"; }

.fa-chevron-up:before {
  content: "\f077"; }

.fa-sign-in-alt:before {
  content: "\f2f6"; }

.fa-user-plus:before {
  content: "\f234"; }

.fa-minus:before {
  content: "\f068"; }

.fa-plus:before {
  content: "\f067"; }

.fa-clock:before {
  content: "\f017"; }

.fa-power-off:before {
  content: "\f011"; }

.fa-instagram:before {
  content: "\f16d"; }

.fa-facebook:before {
  content: "\f09a"; }

.fa-twitter:before {
  content: "\f099"; }

.fa-caret-down:before {
  content: "\f0d7"; }

.fa-caret-left:before {
  content: "\f0d9"; }

.fa-caret-right:before {
  content: "\f0da"; }

.fa-caret-up:before {
  content: "\f0d8"; }

@font-face {
  font-family: 'Font Awesome 5 Brands';
  font-style: normal;
  font-weight: normal;
  font-display: auto;
  src: url("../css/webfonts/fa-brands-400.eot");
  src: url("../css/webfonts/fa-brands-400.eot?#iefix") format("embedded-opentype"), url("../css/webfonts/fa-brands-400.woff2") format("woff2"), url("../css/webfonts/fa-brands-400.woff") format("woff"), url("../css/webfonts/fa-brands-400.ttf") format("truetype"), url("../css/webfonts/fa-brands-400.svg#fontawesome") format("svg"); }

.fab {
  font-family: 'Font Awesome 5 Brands'; }

@font-face {
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url("../css/webfonts/fa-regular-400.eot");
  src: url("../css/webfonts/fa-regular-400.eot?#iefix") format("embedded-opentype"), url("../css/webfonts/fa-regular-400.woff2") format("woff2"), url("../css/webfonts/fa-regular-400.woff") format("woff"), url("../css/webfonts/fa-regular-400.ttf") format("truetype"), url("../css/webfonts/fa-regular-400.svg#fontawesome") format("svg"); }

.far {
  font-family: 'Font Awesome 5 Free';
  font-weight: 400; }

@font-face {
  font-family: 'Font Awesome 5 Free';
  font-style: normal;
  font-weight: 900;
  font-display: auto;
  src: url("../css/webfonts/fa-solid-900.eot");
  src: url("../css/webfonts/fa-solid-900.eot?#iefix") format("embedded-opentype"), url("../css/webfonts/fa-solid-900.woff2") format("woff2"), url("../css/webfonts/fa-solid-900.woff") format("woff"), url("../css/webfonts/fa-solid-900.ttf") format("truetype"), url("../css/webfonts/fa-solid-900.svg#fontawesome") format("svg"); }

.fa,
.fas {
  font-family: 'Font Awesome 5 Free';
  font-weight: 900; }

.btn-mobil,
.mobil {
  display: none; }

.site-navigation {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  padding: 30px 0;
  text-align: center;
  width: 260px;
  height: 100%;
  z-index: 2; }

.logo-mobil {
  display: none; }

.menu__link {
  display: block;
  position: relative;
  font-family: "dinMedium", sans-serif;
  font-size: 1.2rem;
  color: #002F3B;
  padding: 6px 0;
  margin: 6px auto;
  text-align: center;
  width: 80%;
  text-decoration: none;
  transition: color 0.25s ease-in-out; }
  .menu__link:hover {
    color: #4DA6CC; }

.rezervation {
  bottom: 60px;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding-left: 45px;
  padding-right: 45px;
  font-weight: 600;
  border-color: #002F3B;
  color: #002F3B; }
  .rezervation:hover {
    border-color: #4DA6CC; }

.site {
  position: relative;
  background: #f4f4f4;
  padding-left: 260px;
  overflow-x: hidden;
  min-height: 100vh; }
  .site--nopadding {
    padding: 0; }

.navigation {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  background: #002F3B;
  box-shadow: 0 200px 0 0 #002F3B;
  padding: 15px;
  text-align: center;
  width: 70px;
  height: 100%;
  z-index: 4; }
  .navigation__link {
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    color: #4DA6CC;
    margin: 15px auto;
    border: 1px solid rgba(77, 166, 204, 0.5);
    border-radius: 50%;
    width: 36px;
    height: 36px;
    text-decoration: none; }
    .navigation__link:hover {
      background: #4DA6CC;
      color: #fff;
      border-color: #4DA6CC; }
  .navigation__arrow {
    position: absolute;
    bottom: 30px;
    left: 0;
    right: 0;
    margin: 0 auto;
    transform: scale(1); }
    .navigation__arrow path {
      transition: stroke 0.25s ease-in-out; }
    .navigation__arrow:hover svg path {
      stroke: #A8D964; }

.progress-wrapper {
  display: block;
  position: absolute;
  bottom: 30px;
  left: 330px;
  right: 90px;
  width: calc(100% - 470px); }

.progress {
  position: relative;
  background-color: none;
  background-image: linear-gradient(to right, #4DA6CC, #4DA6CC);
  background-repeat: no-repeat;
  background-size: 0 100%;
  overflow: hidden;
  height: 6px;
  transition: background-size 0.4s ease-in-out;
  z-index: 1; }
  .progress--bg {
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.4);
    width: 100%;
    height: 1px;
    z-index: 0; }

.slider {
  position: absolute;
  bottom: 90px;
  left: 260px;
  right: 0;
  width: calc(100% - 330px); }
  .slider .slick-list {
    padding-top: 30px; }

.slider-for .slick-slide {
  min-height: 100vh; }

.slider-detay {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  padding: 30px 90px 90px; }
  .slider-detay__text {
    width: 60%; }
  .slider-detay__baslik {
    display: block;
    font-family: "dinBold", sans-serif;
    font-size: 4rem;
    margin-bottom: 30px;
    line-height: 1.3; }
  .slider-detay__yazi {
    font-family: "dinLight", sans-serif;
    font-size: 1.4rem;
    color: #F2F2F2;
    display: block; }
  .slider-detay__resim {
    width: 45%; }

.image {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin: 0 auto;
  width: 280px; }
  .image__number {
    position: absolute;
    top: -30px;
    left: 6px;
    font-size: 2rem;
    color: #fff;
    z-index: 2; }
  .image__text {
    font-size: 1rem;
    color: #fff;
    color: #F2F2F2;
    margin-left: 15px;
    text-align: left; }
  .image__resim {
    position: relative; }
    .image__resim:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.4);
      width: 100%;
      height: 100%;
      transition: background-color 0.25s ease-in-out; }
  .image:hover {
    cursor: pointer; }
    .image:hover .image__resim:after {
      background: rgba(0, 0, 0, 0); }

.slider-arrow {
  font-size: 1.4rem;
  color: rgba(255, 255, 255, 0.4);
  transition: color 0.25s ease-in-out; }
  .slider-arrow:hover {
    color: #fff; }

.prev {
  left: -30px; }

.next {
  right: -30px; }

.paket {
  position: absolute;
  top: 30px;
  right: 30px;
  z-index: 10001; }

.sepet {
  display: none;
  position: fixed;
  top: 0;
  left: 260px;
  background: #fff;
  height: 100%;
  max-width: calc(100% - 330px);
  z-index: 1000; }
  .sepet--active {
    display: block !important; }

.geri-wrapper {
  background: #EFF3EC;
  padding: 30px 30px 30px 60px; }

.geri-btn {
  font-family: "dinMedium", sans-serif;
  color: #002F3B;
  text-decoration: none;
  transition: color 0.25s ease-in-out; }
  .geri-btn svg path {
    transition: fill 0.25s ease-in-out; }
  .geri-btn:hover {
    color: #4DA6CC; }
    .geri-btn:hover svg path {
      fill: #4DA6CC; }
  .geri-btn span {
    display: inline-block;
    margin-left: 6px; }

.hikaye-bg {
  height: 280px; }

.site-wrapper {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  justify-content: space-between; }

.ic-menu {
  position: relative;
  background-color: #002F3B;
  color: #fff;
  padding: 160px 60px 30px;
  width: 320px;
  min-height: 100vh;
  z-index: 9999; }
  .ic-menu--fixed {
    position: fixed;
    z-index: 3; }
  .ic-menu__baslik {
    display: block;
    font-family: "dinBold", sans-serif;
    font-size: 3rem;
    line-height: 1.4;
    width: 200px;
    margin-bottom: 10px; }
  .ic-menu__altbaslik {
    display: block;
    margin-top: -10px;
    width: 200px; }
  .ic-menu p {
    display: block;
    font-family: "dinLight", sans-serif;
    font-size: 0.9rem;
    color: #F2F2F2;
    width: 200px; }
  .ic-menu__link {
    display: block;
    font-size: 1rem;
    color: #F2F2F2;
    padding: 6px 0;
    margin: 4px 0;
    text-decoration: none;
    transition: color 0.25s ease-in-out; }
    .ic-menu__link--tarih {
      display: block;
      font-family: "dinBold", sans-serif;
      font-size: 1.5rem;
      margin-top: 15px; }
    .ic-menu__link.active {
      color: #4DA6CC; }
    .ic-menu__link:hover {
      color: #4DA6CC; }
  .ic-menu__ot {
    display: none !important;
    position: fixed;
    bottom: 0;
    margin-top: 30px;
    margin-left: -30px;
    width: 260px;
    z-index: 0; }
  .ic-menu__scroll {
    overflow-y: scroll;
    height: 300px; }
    .ic-menu__scroll::-webkit-scrollbar {
      width: 4px; }
    .ic-menu__scroll::-webkit-scrollbar-track {
      background: transparent; }
    .ic-menu__scroll::-webkit-scrollbar-thumb {
      background: rgba(77, 166, 204, 0.4);
      border-radius: 6px; }
    .ic-menu__scroll::-webkit-scrollbar-thumb:hover {
      background: #4DA6CC; }
  .ic-menu--paket .ic-menu__ot {
    display: none; }
  .ic-menu--paket .ic-menu__scroll {
    height: calc(100vh - 420px); }

.ic-wrapper {
  width: calc(100% - 320px); }
  .ic-wrapper--genis {
    width: 100%; }

.hikaye-wrapper {
  position: relative;
  background: #EFF3EC;
  padding: 30px 60px 0; }

.sef {
  display: flex;
  justify-content: space-between; }
  .sef__resim {
    margin-top: -160px;
    width: 36%; }
  .sef__bilgi {
    padding-top: 80px;
    padding-left: 30px;
    width: 70%; }
  .sef__baslik {
    font-family: "dinBold", sans-serif;
    font-size: 3rem; }

.sefresim--1 {
  display: block;
  position: relative;
  top: -30px;
  margin-left: auto; }

.hikaye {
  position: relative;
  padding: 90px 0; }
  .hikaye__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 70%;
    height: 100%; }
  .hikaye__card {
    position: relative;
    background: #fff;
    padding: 90px 180px 90px 90px;
    margin-left: auto;
    width: 70%; }
  .hikaye__gorsel {
    position: absolute;
    top: -180px;
    right: 30px; }
  .hikaye--1 {
    margin-top: 120px;
    margin-bottom: 60px; }

.hikaye--2 .hikaye__card {
  padding-top: 140px;
  padding-bottom: 140px;
  margin-left: 10%;
  width: 60%; }
  .hikaye--2 .hikaye__card p {
    position: relative;
    left: -40%; }

.hikaye--2 .hikaye__gorsel {
  top: -30px;
  left: 30px; }
  .hikaye--2 .hikaye__gorsel--2 {
    top: 50%;
    left: auto;
    right: -45%; }

.sef-hikaye-alt {
  position: relative;
  padding-top: 90px; }

.sef-hikaye-bg {
  position: absolute;
  top: 0;
  left: -60px;
  width: calc(100% + 120px);
  height: 100%;
  z-index: 0; }

.sef-hikaye-yazi {
  position: relative;
  color: #fff; }
  .sef-hikaye-yazi .row {
    position: relative;
    bottom: -60px; }
  .sef-hikaye-yazi:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background: #F2F2F2;
    width: 120px;
    height: 1px; }

.hikaye--3 {
  position: relative;
  bottom: -150px; }
  .hikaye--3 .hikaye__bg {
    left: auto;
    right: 0; }
  .hikaye--3 .hikaye__card {
    padding: 120px 60px 60px;
    margin-left: 0;
    margin-right: auto;
    width: 50%; }
    .hikaye--3 .hikaye__card p {
      font-family: "dinMedium", sans-serif;
      font-size: 1.3rem; }
  .hikaye--3 .hikaye__gorsel {
    left: 0; }

.quote {
  position: relative;
  font-family: "dinMedium", sans-serif;
  font-size: 1.6rem;
  padding: 75px;
  margin: 240px auto 120px;
  text-align: center;
  width: 80%; }
  .quote:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background-image: url("../../dist/img/quote.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 40px;
    height: 40px; }
  .quote:after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    background-image: url("../../dist/img/quote.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 40px;
    height: 40px;
    transform: rotate(180deg); }

.mutfak-slider {
  padding-left: 60px;
  margin-top: 75px;
  width: calc(100% + 60px); }
  .mutfak-slider .item {
    position: relative;
    margin-right: 15px; }
  .mutfak-slider__baslik {
    position: absolute;
    bottom: 15px;
    left: 15px;
    font-family: "dinMedium", sans-serif;
    font-size: 1.3rem;
    padding: 0 6px; }
    .mutfak-slider__baslik span {
      position: relative;
      z-index: 1; }
    .mutfak-slider__baslik:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      background: #fff;
      width: 100%;
      height: 17px;
      z-index: 0; }

.baslik-bg {
  position: absolute;
  top: 130px;
  left: 60px;
  background-image: url("../../dist/img/hikayesvg.svg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 380px; }

.hikaye--4 {
  margin-top: 400px; }
  .hikaye--4 .hikaye__bg {
    top: -260px;
    width: 400px;
    height: 500px; }
  .hikaye--4 .hikaye__card {
    padding: 140px 90px 120px;
    margin-right: auto;
    width: 60%; }
  .hikaye--4 .hikaye__gorsel {
    top: -70px;
    right: -45px;
    width: 170px; }

.mutfak-ust {
  position: relative;
  margin-top: 60px; }

.mutfak-baslik {
  display: block;
  font-family: "dinBlack", sans-serif;
  font-size: 4.2rem;
  line-height: 1.2; }

.mutfak-yazi {
  position: absolute;
  bottom: -290px;
  left: 500px;
  font-family: "dinMedium", sans-serif;
  width: 400px; }
  .mutfak-yazi:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: -30px;
    background: #4DA6CC;
    width: 2px;
    height: 300px; }

.hakkimizda-yazi {
  display: block;
  position: relative;
  top: -120px;
  font-family: "dinMedium", sans-serif;
  padding-left: 30px;
  width: 40%; }

.hakkimizda {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-left: -60px;
  width: calc(100% + 120px); }
  .hakkimizda__item {
    background-color: #fff;
    padding: 140px;
    width: 50%; }
  .hakkimizda__baslik {
    display: block;
    font-family: "dinBold", sans-serif;
    font-size: 1.6rem;
    color: #4DA6CC;
    margin-bottom: 30px; }

.menu-ust {
  justify-content: flex-start; }
  .menu-ust .slider-detay__baslik {
    font-size: 2.7rem; }
  .menu-ust .slider-detay__yazi {
    font-size: 1.1rem; }
  .menu-ust .slider-detay__resim {
    width: 100% !important; }
  .menu-ust .slider-detay__text {
    width: 44%; }
  .menu-ust__resim {
    width: 44%; }

.urunler {
  padding-bottom: 15px;
  margin-left: 90px;
  width: calc(100% - 150px); }
  .urunler__baslik {
    display: block;
    font-family: "dinBold", sans-serif;
    font-size: 2.5rem;
    color: #fff;
    margin-bottom: 15px; }
  .urunler__altbaslik {
    color: #F2F2F2; }
  .urunler__nav {
    display: block;
    margin-bottom: 30px; }
  .urunler__link {
    color: #fff;
    transition: color 0.25s ease-in-out;
    text-decoration: none;
    display: inline-block;
    margin-right: 25px;
    font-size: 1rem; }
    .urunler__link.active, .urunler__link:hover {
      color: #4DA6CC; }
  .urunler__scroll {
    cursor: none !important;
    margin-left: -15px;
    overflow-x: scroll; }
    .urunler__scroll:hover #cursor {
      opacity: 1;
      transform: scale(1);
      z-index: 2; }
    .urunler__scroll::-webkit-scrollbar {
      height: 4px; }
    .urunler__scroll::-webkit-scrollbar-track {
      background: transparent; }
    .urunler__scroll::-webkit-scrollbar-thumb {
      background: rgba(77, 166, 204, 0.4);
      border-radius: 6px; }
    .urunler__scroll::-webkit-scrollbar-thumb:hover {
      background: #4DA6CC; }
  .urunler__slider {
    cursor: none !important;
    width: auto;
    white-space: nowrap; }

.urun {
  display: inline-block;
  position: relative;
  cursor: none !important;
  margin-right: 30px;
  width: 400px; }
  .urun:first-child {
    margin-left: 15px; }
  .urun__baslik {
    position: absolute;
    top: 15px;
    left: -15px;
    font-size: 1rem;
    background: #4DA6CC;
    color: #fff;
    padding: 2px 8px; }
  .urun--galeri img:hover {
    transform: scale(1.05); }

#cursor {
  position: absolute;
  opacity: 0;
  transform: scale(0);
  transition: transform 0.3s ease-in-out; }

.menu-bg {
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: cover; }

.scroll--galeri {
  padding-left: 0;
  margin-left: 0;
  width: 100%; }
  .scroll--galeri .urun {
    width: 700px; }

.etkinlik {
  display: inline-block;
  background: #fff;
  margin-right: 30px;
  border: 1px solid #dfdfdf;
  width: 600px; }
  .etkinlik:hover {
    box-shadow: 0 0 0 4px rgba(77, 166, 204, 0.3);
    border-color: #4DA6CC; }
  .etkinlik a {
    text-decoration: none; }
  .etkinlik__bg {
    margin-right: 30px;
    width: 100%;
    height: 400px; }
  .etkinlik__alt {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: flex-end;
    position: relative;
    padding: 30px 30px 90px;
    margin-top: -60px; }
  .etkinlik__tarih {
    font-family: "dinBold", sans-serif;
    background: #002F3B;
    color: #fff;
    padding: 15px;
    line-height: 1.2;
    text-align: center;
    width: 120px; }
  .etkinlik__gun {
    display: block;
    font-size: 1.4rem;
    padding-bottom: 6px;
    margin-bottom: 6px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1); }
  .etkinlik__saat {
    font-size: 1.6rem; }
  .etkinlik__text {
    margin-left: 15px;
    white-space: normal; }
  .etkinlik__baslik {
    display: block;
    font-family: "dinBold", sans-serif;
    font-size: 1.2rem;
    color: #002F3B; }
  .etkinlik__kisa {
    font-size: 1rem;
    color: #002F3B; }
  .etkinlik__link {
    color: #4DA6CC;
    transition: color 0.25s ease-in-out;
    position: absolute;
    right: 30px;
    bottom: 30px;
    text-decoration: underline;
    font-family: "dinBold", sans-serif; }
    .etkinlik__link:hover {
      color: #002F3B; }

.etkinlik--galeri {
  margin-left: 0;
  width: 100%; }
  .etkinlik--galeri .urunler__scroll {
    padding: 6px;
    margin-left: 0; }
  .etkinlik--galeri .urunler__slider {
    padding-bottom: 15px; }

.ml-sol {
  margin-left: 30px; }

.icerik {
  padding: 0 100px 0 70px; }
  .icerik__resim {
    margin-bottom: 30px;
    height: 430px; }
  .icerik__alt {
    display: flex;
    align-items: flex-start;
    position: relative; }
  .icerik__text {
    margin-left: 30px; }
  .icerik__baslik {
    display: block;
    font-family: "dinBold", sans-serif;
    font-size: 2.3rem;
    margin-bottom: 30px;
    line-height: 1.2; }
  .icerik__yazi {
    line-height: 1.6; }
  .icerik__link {
    font-size: 1.2rem;
    color: #4DA6CC;
    text-decoration: none; }
    .icerik__link:hover {
      text-decoration: underline; }

.sayfa {
  position: relative;
  padding-top: 160px;
  width: 100%; }
  .sayfa__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 350px;
    z-index: 0; }
  .sayfa__wrapper {
    position: relative;
    background: #fff;
    padding: 30px;
    margin: 0 auto;
    border: 1px solid #F2F2F2;
    width: calc(100% - 60px);
    z-index: 3; }
  .sayfa__baslik {
    display: block;
    font-family: "dinBold", sans-serif;
    font-size: 2rem;
    line-height: 1.2; }
  .sayfa__altyazi {
    font-family: "dinMedium", sans-serif;
    font-size: 0.9rem; }

.iletisim-box {
  display: block;
  font-family: "dinMedium", sans-serif;
  width: 100%; }
  .iletisim-box__label {
    display: block;
    font-size: 0.9rem;
    color: #4DA6CC;
    line-height: 1; }
  .iletisim-box a {
    color: #002F3B;
    text-decoration: none; }
    .iletisim-box a:hover {
      text-decoration: underline; }

.breadcrumb {
  position: relative;
  font-size: 1rem;
  color: #fff;
  margin-bottom: 15px;
  margin-left: 30px;
  z-index: 1; }
  .breadcrumb__link {
    display: inline-block;
    color: #fff;
    text-decoration: none; }
    .breadcrumb__link:hover {
      text-decoration: underline; }
  .breadcrumb__ayrac {
    display: inline-block;
    margin: 0 4px; }

.form-control {
  display: block;
  font-size: 1rem;
  background: #fff;
  padding: 0 8px;
  border: 1px solid #E0E0E0;
  line-height: 44px;
  outline: transparent;
  width: 100%;
  height: 44px;
  appearance: none;
  -webkit-appearance: none; }
  .form-control:focus, .form-control:hover {
    box-shadow: 0 0 0 3px rgba(77, 166, 204, 0.3);
    border-color: #4DA6CC; }

.form-label {
  display: block;
  font-family: "dinMedium", sans-serif;
  font-size: 0.9rem;
  margin-bottom: 8px; }

.form-yazi {
  font-family: "dinMedium", sans-serif; }
  .form-yazi strong {
    font-family: "dinBold", sans-serif;
    font-size: 1.5rem;
    font-weight: normal; }

.galeri--slider {
  padding: 30px 0; }

.paket--wrapper {
  margin-top: 120px; }

.paket-baslik {
  margin-left: 30px;
  font-size: 1.8rem;
  color: #4DA6CC;
  font-family: "dinBold", sans-serif;
  display: block;
  margin-bottom: 15px; }

.sepet-icon {
  position: absolute;
  top: -80px;
  left: 30px;
  color: #002F3B;
  text-decoration: none;
  font-size: 1.5rem; }

.sepet-sayi {
  font-size: 1rem;
  font-family: "dinMedium", sans-serif;
  margin-left: 8px; }

.paket-wrapper {
  width: calc(100% - 120px); }

.paket-scroll {
  overflow-y: scroll; }

.servis-wrapper {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  justify-content: space-between; }

.servis-liste {
  height: 600px;
  overflow-y: scroll; }
  .servis-liste::-webkit-scrollbar {
    width: 4px; }
  .servis-liste::-webkit-scrollbar-track {
    background: transparent; }
  .servis-liste::-webkit-scrollbar-thumb {
    background: rgba(77, 166, 204, 0.4);
    border-radius: 6px; }
  .servis-liste::-webkit-scrollbar-thumb:hover {
    background: #4DA6CC; }

.servis {
  position: relative;
  border-bottom: 1px solid #F2F2F2;
  padding-bottom: 30px;
  margin-bottom: 30px;
  margin-right: 30px; }
  .servis__resim {
    height: 100%; }
  .servis__baslik {
    font-size: 1.7rem;
    display: block;
    font-family: "dinMedium", sans-serif;
    width: calc(100% - 55px);
    line-height: 1.4;
    margin-bottom: 7px; }
  .servis__aciklama {
    font-size: 1rem;
    width: 270px;
    display: block;
    color: #828282; }
  .servis__fiyat {
    position: absolute;
    top: 0;
    right: 30px;
    font-size: 1.9rem;
    font-family: "dinBold", sans-serif; }
  .servis__currency {
    font-family: "dinMedium", sans-serif;
    font-size: 1.3rem; }
  .servis__butonlar {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
    align-items: center; }
  .servis__number {
    width: 110px;
    font-size: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #E0E0E0;
    border-radius: 32px; }
    .servis__number a {
      text-decoration: none;
      color: #828282;
      display: inline-block;
      padding: 0 8px;
      font-size: 0.8rem;
      transition: color 0.25s ease-in-out; }
      .servis__number a:hover {
        color: #4DA6CC; }
    .servis__number input {
      width: 40px;
      font-size: 1rem;
      height: 38px;
      line-height: 38px;
      border: none;
      text-align: center;
      color: #002F3B;
      font-family: "dinBold", sans-serif; }
  .servis__btn {
    height: 38px;
    line-height: 38px;
    border-radius: 32px;
    text-align: center;
    box-shadow: inset 0 0 0 1px #4DA6CC;
    padding: 0 18px;
    font-size: 0.9rem;
    color: #4DA6CC;
    text-decoration: none;
    font-family: "dinMedium", sans-serif; }
    .servis__btn:hover {
      background: #002F3B;
      box-shadow: inset 0 0 0 1px #002F3B;
      color: #fff; }
  .servis__btn--yesil {
    background: #4DA6CC;
    color: #fff;
    box-shadow: none; }

.paket-sayfa {
  width: 100%;
  margin-left: 30px; }

.modal-bg {
  z-index: 10002;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  display: none; }

.sepet-modal {
  width: 70%;
  background: #fff;
  padding: 30px;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 10003;
  display: none; }

.modal-close {
  color: #fff;
  position: absolute;
  right: -5px;
  top: -60px;
  transition: opacity 0.25s ease-in-out; }
  .modal-close:hover {
    opacity: .5; }

.icindekiler span {
  font-size: 1rem;
  line-height: 1.2; }

.sepet-adetler {
  display: flex;
  justify-content: flex-start;
  align-items: center; }
  .sepet-adetler strong {
    display: inline-block;
    width: 90px; }
  .sepet-adetler select {
    border-radius: 32px; }

.modal-butonlar {
  text-align: right;
  margin-top: 90px; }
  .modal-butonlar .servis__btn {
    display: inline-block;
    height: 50px;
    line-height: 50px;
    padding: 0 32px; }

.sepet-fiyat {
  font-size: 1.6rem;
  color: #4DA6CC;
  font-family: "dinBold", sans-serif; }

.ust-baslik {
  margin-left: 30px;
  margin-bottom: 15px; }

.logo-normal {
  display: none; }

.sol-baslik {
  margin-left: 30px; }

.saat {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: .9rem;
  width: 100%;
  margin-top: 15px; }
  .saat strong {
    font-size: .9rem !important; }
  .saat span {
    float: right; }

.menu-sosyal a {
  margin: 10px;
  border-radius: 50%;
  width: 38px;
  height: 38px;
  display: inline-block;
  line-height: 34px;
  color: #002F3B;
  border: 1px solid #002F3B; }
  .menu-sosyal a:hover {
    background: #002F3B;
    color: #fff; }

.giris-logo {
  position: absolute;
  left: 30px;
  top: 30px;
  z-index: 3; }

.giris {
  position: relative; }
  .giris .container-fluid, .giris .row {
    min-height: 100vh;
    height: 100%; }
  .giris .row {
    align-items: center; }

.giris-club {
  padding: 10%; }
  .giris-club .yazi {
    color: #fff; }
  .giris-club .btn {
    color: #fff;
    border-color: #fff; }

.giris-restorant {
  padding: 10%; }

.popup-bg {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  height: 100%; }

.popup {
  width: 1024px;
  max-width: calc(100% - 30px);
  left: 15px;
  right: 15px;
  margin: 0 auto;
  z-index: 99999;
  background: #fff;
  max-height: 90vh; }
  .popup img {
    vertical-align: middle; }

.popup-icerik {
  overflow: hidden; }

.popup-kapat {
  position: absolute;
  right: -7px;
  top: -7px;
  font-size: 1rem;
  color: #fff;
  background: #4DA6CC;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  text-align: center;
  line-height: 24px; }
  .popup-kapat:hover {
    color: #002F3B; }

.dil-link {
  display: inline-block;
  font-size: 1rem;
  color: #4DA6CC;
  text-decoration: none;
  margin-left: 15px; }
  .dil-link:hover {
    text-decoration: underline; }

.dil-linkler {
  position: absolute;
  right: -30px;
  top: 15px; }

.oneri-wrapper {
  border: 1px solid rgba(43, 41, 45, 0.2);
  padding: 15px; }

.oneri-slider {
  position: relative; }
  .oneri-slider:after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    background: #fff;
    height: 100%;
    width: 15px;
    z-index: 1; }
  .oneri-slider .slick-arrow {
    z-index: 4; }
    .oneri-slider .slick-arrow:before {
      color: #002F3B; }
  .oneri-slider .slick-prev {
    left: 0; }
  .oneri-slider .slick-next {
    right: 0; }

.oneri {
  display: flex !important;
  justify-content: center;
  align-items: center;
  border-right: 1px solid rgba(43, 41, 45, 0.2); }
  .oneri__detay {
    padding-left: 15px;
    position: relative; }
  .oneri__baslik {
    color: #002F3B;
    font-weight: 600;
    font-size: .9rem;
    display: block;
    margin-bottom: 30px; }
  .oneri__fiyat {
    color: #4DA6CC; }

@media (max-width: 1800px) {
  .mutfak-yazi {
    width: 45%; }
  .baslik-bg {
    width: calc(100% - 120px); } }

@media (max-width: 1600px) {
  .slider-detay__text {
    width: 50%; }
  .slider-detay__baslik {
    font-size: 3.5rem; }
  .slider-detay {
    padding: 30px; }
  .hikaye--1 .hikaye__gorsel {
    width: 150px; }
  .hikaye--1 .hikaye__card {
    padding: 60px; }
  .hikaye--2 .hikaye__gorsel--2 {
    width: 40%;
    right: -30px; }
  .hikaye--2 .hikaye__card {
    width: 100%;
    margin-left: 0; }
    .hikaye--2 .hikaye__card p {
      left: 0;
      width: 60%; }
  .mutfak-yazi {
    position: relative;
    left: 30px;
    bottom: 0;
    top: 0;
    margin-top: 120px;
    width: calc(100% - 100px); }
    .mutfak-yazi:before {
      height: 200px; }
  .hikaye--4 {
    margin-top: 330px; }
  .hikaye--4 .hikaye__card {
    width: 80%; }
  .urunler {
    margin-left: 30px;
    width: calc(100% - 120px); }
  .etkinlik {
    width: 500px; }
    .etkinlik__alt {
      margin-top: -45px; }
  .etkinlik--galeri {
    width: 100%;
    margin-left: 0; }
  .servis__butonlar {
    flex-wrap: wrap;
    justify-content: center; }
    .servis__butonlar .servis__btn--yesil {
      width: 250px;
      margin-right: 10px;
      margin-top: 15px; } }

@media (max-width: 1400px) {
  .servis__fiyat {
    right: 0;
    top: -10px; }
  .servis__baslik {
    font-size: 1.4rem; }
  .servis__butonlar {
    flex-wrap: wrap;
    justify-content: center; }
    .servis__butonlar .servis__number {
      width: 100%;
      margin-bottom: 6px; }
    .servis__butonlar .servis__btn {
      width: 100%;
      margin: 6px 0 !important; }
      .servis__butonlar .servis__btn--yesil {
        width: 100%;
        margin: 6px 0; }
  .etkinlik {
    width: 350px;
    position: relative; }
    .etkinlik__bg {
      height: 300px; }
    .etkinlik__alt {
      top: 0; }
    .etkinlik__text {
      padding-top: 30px;
      width: 100%;
      margin-left: 0; }
    .etkinlik__tarih {
      position: absolute;
      left: 15px;
      top: -95px; }
  .hakkimizda-yazi {
    width: 100%;
    margin-top: 175px; }
  .ic-menu {
    width: 280px;
    padding-left: 30px;
    padding-right: 30px; }
  .hakkimizda__item {
    padding: 120px 40px; }
  .ic-wrapper {
    width: calc(100% - 280px); }
  .sef {
    flex-wrap: wrap; }
    .sef__resim {
      width: 300px;
      margin-left: auto;
      margin-right: auto;
      max-width: 100%; }
    .sef__bilgi {
      text-align: center;
      width: 100%;
      padding-top: 30px;
      padding-left: 0; }
  .sefresim--1 {
    top: 0;
    margin-top: 30px; }
  .hikaye--1 .hikaye__gorsel {
    width: 90px;
    top: -90px; }
  .hikaye--2 .hikaye__gorsel--2 {
    width: 240px;
    bottom: -90px;
    top: auto;
    transform: none; }
  .hikaye--2 .hikaye__card {
    width: 100%;
    margin-left: 0;
    padding: 160px 60px;
    margin-bottom: 30px; }
    .hikaye--2 .hikaye__card p {
      width: 100%; }
  .hikaye--3 .hikaye__card {
    width: 70%; }
  .icerik__alt {
    position: relative; }
  .icerik__text {
    margin-left: 0;
    margin-top: 60px; } }

@media (max-width: 1200px) {
  .paket-wrapper {
    width: calc(100% - 60px); }
  .hakkimizda {
    width: calc(100% + 60px);
    margin-left: -30px; }
  .site-navigation {
    left: -300px;
    z-index: 10001;
    transition: left 0.25s ease-in-out; }
  .mobil-bg {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 100%;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    z-index: 10001; }
  .arka-active {
    display: block;
    opacity: 1; }
  .mobil-active {
    left: 0; }
  .navigation {
    padding-top: 60px; }
  .btn-mobil {
    display: block;
    position: fixed;
    top: 12px;
    right: 0;
    z-index: 10035;
    outline: transparent; }
  .site {
    margin-left: 0;
    max-width: 100%;
    padding-left: 0; }
  .urunler {
    width: calc(100% - 60px); }
  .urun {
    width: 300px; }
  .slider-detay {
    padding: 30px; }
  .progress-wrapper {
    width: calc(100% - 120px);
    left: 60px;
    right: 60px; }
  .slider-detay__baslik {
    font-size: 3rem; }
  .paket {
    top: 15px;
    right: 90px; }
  .hikaye-wrapper {
    padding: 30px;
    padding-bottom: 0; }
  .slider {
    left: 30px;
    width: calc(100% - 70px); }
  .etkinlik--galeri {
    width: 100%; }
  .icerik {
    padding: 0 80px; }
  .sayfa {
    width: 100%; } }

@media (max-height: 680px) {
  .slider-detay {
    padding-bottom: 280px; } }

@media (max-width: 992px) {
  .slider-detay {
    flex-direction: column-reverse;
    flex-wrap: wrap; }
    .slider-detay__text {
      width: 100%;
      text-align: center;
      margin-top: 30px; }
    .slider-detay__resim {
      width: 40%;
      margin: 0 auto; }
  .slider-detay__baslik {
    font-size: 1.5rem; }
  .slider-detay__resim {
    width: 300px;
    max-width: 100%; }
  .slider-detay__yazi {
    font-size: 1.1rem; }
  .logo-mobil {
    position: absolute;
    display: block;
    left: 15px;
    top: 15px;
    z-index: 10000; }
    .logo-mobil img {
      width: 80px; }
  .btn {
    font-size: 1rem; }
  .site-wrapper {
    flex-wrap: wrap; }
  .ic-menu {
    width: 100%;
    padding-left: 15px; }
  .ic-wrapper {
    width: 100%; }
  .ic-menu--fixed {
    position: relative; }
  .ic-menu__ot {
    display: none; }
  .urunler__nav {
    width: auto;
    white-space: nowrap;
    overflow-x: scroll;
    padding-bottom: 6px; }
  .menu-ust .slider-detay__text {
    width: 100%; }
  .ic-menu {
    min-height: auto; }
  .icerik {
    padding: 30px; }
  .paket-wrapper {
    width: 100%; }
  .paket-sayfa {
    width: calc(100% - 60px); }
  .servis__resim {
    height: 300px;
    margin-bottom: 60px; }
  .servis-liste {
    height: auto;
    overflow: auto; }
  .servis__butonlar {
    justify-content: space-between; }
    .servis__butonlar .servis__number {
      width: 30%; }
    .servis__butonlar .servis__btn {
      width: 30%; }
  .sepet-modal {
    max-height: 90vh;
    overflow-y: scroll; }
  .modal-close {
    top: 5px;
    right: 5px;
    background: #828282;
    height: 37px;
    width: 37px;
    line-height: 37px;
    border-radius: 50%;
    z-index: 9; }
  .dil-linkler {
    right: 30px; }
  .giris-logo {
    left: 0;
    right: 0;
    margin: 0 auto; } }

@media (max-width: 768px) {
  .slider-detay {
    padding: 110px 15px 280px; }
  .navigation {
    width: 50px;
    padding-left: 0;
    padding-right: 0;
    padding-top: 45px; }
  .btn-mobil {
    transform: scale(0.7);
    padding: 0;
    padding-right: 5px;
    top: 20px; }
  .site {
    max-width: 100%; }
  .navigation__link {
    width: 28px;
    height: 28px;
    font-size: 0.9rem; }
  .navigation__arrow svg {
    width: 12px; }
  .paket {
    right: 60px; }
  .sef__baslik {
    font-size: 2rem; }
  .hikaye--1 .hikaye__card {
    width: 80%;
    padding-left: 30px;
    padding-right: 30px; }
  .hikaye--2 .hikaye__card {
    padding-left: 30px;
    padding-right: 30px; }
  .hikaye--3 .hikaye__card {
    padding-left: 30px;
    padding-right: 30px; }
  .hikaye--3 .hikaye__card {
    width: 90%; }
  .hikaye--4 .hikaye__card {
    padding: 120px 30px 60px; }
  .hikaye--4 .hikaye__gorsel {
    width: 90px;
    right: -15px; }
  .mutfak-baslik {
    font-size: 3.2rem; }
  .mutfak-slider {
    width: 100%; }
  .baslik-bg {
    display: none; }
  .mutfak-slider {
    padding-left: 0; }
  .hikaye--4 .hikaye__bg {
    width: 70%; }
  .urun {
    width: 250px; }
  .scroll--galeri .urun {
    width: 500px; }
  .icerik__baslik {
    font-size: 1.6rem; }
  .servis__butonlar {
    justify-content: space-between; }
    .servis__butonlar .servis__number {
      width: 100%; }
    .servis__butonlar .servis__btn {
      width: 100%; }
  .sepet-modal {
    width: calc(100% - 30px); }
  .modal-butonlar {
    text-align: center; }
    .modal-butonlar a {
      margin: 15px 0 !important; }
  .sepet-modal {
    padding: 15px; } }

.sepet-table {
  color: #002F3B;
  border: 1px solid #E9E9E9;
  border-radius: 0.25rem; }

.sepet-table__ust {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "dinMedium", sans-serif;
  font-size: 1.1rem;
  background: #E9E9E9;
  padding: 15px; }

.sepet-bilgiler {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 60px;
  width: 360px; }

.sepet-tr {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
  padding: 15px;
  border-bottom: 1px solid #dfdfdf; }
  .sepet-tr:last-child {
    border: none; }

.sepet-urun {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: calc(100% - 400px); }
  .sepet-urun__resim {
    margin-right: 25px; }
  .sepet-urun__baslik {
    font-family: "dinMedium", sans-serif;
    color: #4DA6CC; }
  .sepet-urun__aciklama {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    font-family: "dinMedium", sans-serif;
    padding-left: 30px;
    margin-left: 30px;
    border-left: 1px solid #dfdfdf;
    height: 50px; }

.sepet-fiyatlar {
  width: 50%;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "dinMedium", sans-serif;
  margin-left: 30px;
  width: 340px; }
  .sepet-fiyatlar__fiyat {
    position: relative;
    left: -15px; }
  .sepet-fiyatlar__input {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    position: relative;
    left: -16px;
    padding: 0 40px;
    border-left: 1px solid #dfdfdf;
    border-right: 1px solid #dfdfdf;
    height: 50px; }
    .sepet-fiyatlar__input input {
      border: 1px solid #dfdfdf;
      outline: none;
      text-align: center;
      width: 40px;
      height: 40px; }
  .sepet-fiyatlar__tutar {
    font-family: "dinBold", sans-serif;
    font-size: 1.1rem;
    color: #4DA6CC; }

.sepet-sil {
  font-size: 1.3rem;
  color: #EB5757;
  transition: color 0.25s ease-in-out; }
  .sepet-sil:hover {
    color: #CD0000; }

.sepet-toplam {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "dinBold", sans-serif;
  font-size: 1.3rem;
  padding-right: 30px;
  margin-left: auto; }
  .sepet-toplam__baslik {
    display: inline-block;
    padding: 0 25px;
    margin-right: 45px;
    border-left: 1px solid #dfdfdf;
    border-right: 1px solid #dfdfdf; }
  .sepet-toplam__son {
    color: #4DA6CC; }

.diger-urun {
  border-right: 1px solid #dfdfdf;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  justify-content: center;
  align-items: center; }
  .diger-urun__bilgi {
    padding-left: 15px; }
  .diger-urun__baslik {
    display: block;
    font-family: "dinBold", sans-serif; }
  .diger-urun__fiyat {
    display: block;
    color: #4DA6CC;
    font-family: "dinMedium", sans-serif; }
  .diger-urun__sepet {
    padding: 2px 12px 4px;
    border-radius: 32px;
    display: block;
    margin-top: 10px;
    font-size: 0.9rem;
    font-family: "dinMedium", sans-serif;
    background: #002F3B;
    color: #fff;
    text-decoration: none; }
    .diger-urun__sepet:Hover {
      background: #4DA6CC; }
    .diger-urun__sepet i {
      font-size: 0.7rem; }

.diger-urunler .slick-prev {
  left: 0;
  z-index: 3;
  border-radius: 50%;
  padding: 2px; }
  .diger-urunler .slick-prev:before {
    color: #828282; }

.diger-urunler .slick-next {
  right: 0;
  z-index: 3;
  border-radius: 50%; }
  .diger-urunler .slick-next:before {
    color: #828282; }

.odeme-table {
  border: 1px solid #dfdfdf;
  border-radius: .25rem; }

.odeme {
  padding: 15px;
  border-bottom: 1px solid #dfdfdf;
  font-family: "dinBold", sans-serif;
  font-size: .9rem;
  position: relative;
  padding-right: 60px; }
  .odeme:last-child {
    border: none; }
  .odeme label {
    cursor: pointer; }
  .odeme input {
    position: relative;
    top: 2px; }

.odeme-resim {
  right: 15px; }

.diger-odeme {
  display: none; }
  .diger-odeme .odeme {
    padding-left: 30px; }

.odeme-aciklama {
  color: #a9a9a9;
  font-size: .9rem;
  font-family: "dinMedium", sans-serif;
  display: inline-block;
  margin-left: 15px; }

.odeme-alt .custom-control-label {
  font-size: .9rem;
  font-family: "dinMedium", sans-serif; }

.odeme-alt-btn {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: center;
  justify-content: flex-end; }

@media (max-width: 1400px) {
  .sepet-urun__aciklama {
    font-size: 0.9rem;
    height: auto; } }

@media (max-width: 1200px) {
  .sepet-scroll {
    overflow-x: scroll; }
    .sepet-scroll .sepet-table {
      width: 1200px; } }

@media (max-width: 992px) {
  .diger-urun {
    border: none; }
  .odeme-alt-btn {
    justify-content: center; }
  .odeme-alt-yesil {
    text-align: center; }
    .odeme-alt-yesil img {
      display: block;
      margin: 0 auto; } }

@media (max-width: 450px) {
  .diger-urun {
    flex-wrap: wrap; } }

@media (max-width: 576px) {
  .btn {
    font-size: 0.9rem; }
  .menu__link {
    font-size: 1rem; }
  .logo img {
    width: 100px; }
  .hikaye--1 .hikaye__bg {
    width: 100%; }
  .hikaye--1 .hikaye__card {
    width: calc(100% - 30px);
    margin-left: 15px; }
  .hikaye--1 .hikaye__gorsel {
    position: relative;
    top: 0;
    left: 0; }
  .hikaye-wrapper {
    padding: 15px; }
  .hikaye__card,
  .sef-hikaye-yazi {
    font-size: 0.9rem; }
  .hikaye--2 .hikaye__gorsel--2 {
    right: -15px; }
  .hikaye--3 .hikaye__card p {
    font-size: 1rem; }
  .sef-hikaye-bg {
    left: -30px;
    width: calc(100% + 60px); }
  .quote {
    width: 100%;
    padding: 60px 15px;
    font-size: 1.2rem; }
    .quote:after, .quote:before {
      width: 30px;
      height: 30px; }
  .mutfak-baslik {
    font-size: 2.2rem; }
  .hakkimizda__item {
    width: 100%; }
  .hakkimizda--2 {
    flex-direction: column-reverse; }
  .urun {
    width: 150px; }
  .menu-ust {
    margin-bottom: 30px; }
  .menu-bg {
    background-image: url("../img/bg.jpg") !important; }
  .etkinlik {
    width: 300px;
    margin-right: 15px; }
    .etkinlik__bg {
      height: 200px; }
  .icerik {
    padding: 15px 15px 50px; }
  .sayfa__wrapper {
    width: calc(100% - 30px);
    padding: 15px; }
  .iletisim-box {
    font-size: 1rem; }
  .paket--wrapper {
    width: calc(100% - 30px); }
  .servis {
    margin-right: 0; }
  .sepet-adetler {
    flex-wrap: wrap; }
  .sepet-fiyat {
    width: 100%;
    display: block;
    margin: 15px 0; }
  .sepet-adetler {
    margin-top: 0 !important; }
    .sepet-adetler strong {
      display: block;
      width: 100%; }
    .sepet-adetler .servis__number,
    .sepet-adetler select {
      margin-left: 0 !important;
      margin-top: 15px; }
  .site {
    width: 100%;
    max-width: 100%; }
  .navigation {
    background: none;
    height: 150px;
    box-shadow: none;
    z-index: 10001; }
  .slider-detay__baslik {
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 10px; }
  .slider-detay__yazi {
    font-size: 0.9rem; }
  .slider-detay {
    padding-top: 100px;
    padding-bottom: 190px; }
  .navigation__arrow {
    display: none; }
  .paket {
    padding: 3px 8px;
    font-size: 0.8rem; }
  .image__text {
    font-size: 0.9rem; }
  .slider {
    bottom: 66px; }
  .image__number {
    font-size: 1.4rem;
    top: -20px; }
  body {
    line-height: 1.5; }
  .ic-menu__baslik {
    font-size: 1.2rem; }
  .ic-menu {
    padding-top: 120px;
    padding-bottom: 6px; }
  .ic-menu__link {
    font-size: 0.8rem; }
  .hikaye-bg {
    height: 100px; }
  .sef__resim {
    width: 120px;
    margin-top: -70px; }
  .sef__baslik {
    font-size: 1.2rem;
    margin-bottom: 6px; }
  .yazi {
    font-size: 0.9rem; }
  .hikaye {
    padding: 15px; }
  .hikaye--1 {
    margin-top: 30px;
    padding: 15px 0; }
  .hikaye--1 .hikaye__card {
    padding: 15px; }
  .hikaye--1 .hikaye__gorsel {
    width: 60px; }
  .hikaye--2 .hikaye__card {
    padding-top: 60px;
    padding-bottom: 50px; }
  .hikaye--2 .hikaye__gorsel {
    width: 100px; }
  .hikaye--2 .hikaye__gorsel--2 {
    bottom: -40px; }
  .sef__bilgi {
    padding-top: 0; }
  .sef-hikaye-alt {
    padding-top: 0; }
  .sef-hikaye-yazi:before {
    display: none; }
  .hikaye--3 .hikaye__gorsel {
    width: 100px;
    top: -51px; }
  .hikaye--3 .hikaye__card {
    padding: 45px 15px 15px;
    font-size: 1rem; }
  .quote {
    padding: 30px 15px;
    margin-top: 200px;
    margin-bottom: 0; }
  .baslik {
    font-size: 1.2rem; }
  .mutfak-slider {
    margin-top: 15px; }
    .mutfak-slider img {
      max-width: 100%; }
    .mutfak-slider .item {
      margin-right: 6px; }
  .mutfak-slider__baslik span {
    font-size: 0.8rem; }
  .mutfak-slider__baslik:after {
    height: 12px; }
  .mutfak-baslik {
    font-size: 1.2rem;
    line-height: 1.5; }
  .mutfak-yazi {
    font-size: 0.9rem;
    width: calc(100% - 45px);
    margin-top: 45px; }
    .mutfak-yazi:before {
      height: 140px; }
  .hikaye--4 {
    margin-top: 140px; }
  .hikaye--4 .hikaye__bg {
    top: -100px; }
  .hikaye--4 .hikaye__gorsel {
    width: 70px; }
  .hikaye--4 .hikaye__card {
    padding: 60px 15px;
    width: 100%; }
  .hikaye-wrapper--hakkimizda {
    margin-top: 15px; }
    .hikaye-wrapper--hakkimizda .mt-5 {
      margin-top: 15px !important; }
  .hakkimizda-yazi {
    font-size: 0.9rem;
    padding-left: 0;
    top: 0;
    margin-top: 25px;
    padding-bottom: 15px; }
  .hakkimizda {
    width: 100%;
    margin-left: 0;
    margin-top: 15px; }
    .hakkimizda__item {
      padding: 15px; }
    .hakkimizda__yazi {
      font-size: 0.9rem; }
  .hakkimizda__baslik {
    font-size: 1.2rem; }
  .hmresim {
    height: 150px; }
  .menu-ust .slider-detay__baslik {
    font-size: 1.2rem; }
  .menu-ust .slider-detay__yazi {
    font-size: 0.9rem; }
  .urunler__baslik {
    font-size: 1.2rem; }
  .urun__baslik {
    font-size: 0.8rem; }
  .galeri--slider {
    width: 100%;
    white-space: wrap;
    padding-top: 0; }
  .urunler {
    width: 100%;
    margin-left: 0;
    padding-left: 15px;
    padding-right: 15px; }
  .scroll--galeri .urun {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
    display: block;
    margin-bottom: 10px; }
  .mobil-scroll {
    margin-left: 0;
    overflow: auto; }
  .cursor {
    display: none; }
  .ic-menu__scroll {
    overflow-y: auto;
    overflow-x: scroll;
    white-space: nowrap;
    height: auto; }
    .ic-menu__scroll::-webkit-scrollbar {
      height: 4px; }
    .ic-menu__scroll .ic-menu__link {
      display: inline-block;
      margin-right: 10px;
      margin-top: 0; }
  .ic-menu {
    padding-right: 15px; }
  .etkinlik--galeri {
    margin-top: 30px !important; }
  .ml-sol {
    margin-left: 0; }
  .etkinlik-mobil {
    overflow: auto;
    margin-top: 15px !important; }
    .etkinlik-mobil .urunler__slider {
      white-space: inherit; }
  .etkinlik {
    width: 100%;
    margin-right: 0;
    margin-bottom: 15px; }
    .etkinlik__alt {
      padding-right: 15px;
      padding-left: 15px;
      padding-bottom: 45px; }
    .etkinlik__baslik {
      font-size: 1rem; }
    .etkinlik__kisa {
      font-size: 0.9rem; }
    .etkinlik__link {
      bottom: 15px;
      right: 15px;
      font-size: 0.9rem; }
    .etkinlik__tarih {
      width: 80px; }
    .etkinlik__gun {
      font-size: 1.1rem; }
    .etkinlik__saat {
      font-size: 1.1rem; }
  .sayfa {
    padding-top: 120px; }
  .sayfa__baslik {
    font-size: 1.2rem; }
  .mt-5-mobil {
    margin-top: 15px !important; }
  .form-yazi strong {
    font-size: 1.2rem; }
  .btn {
    padding: 6px 28px !important; }
  .geri-btn {
    font-size: 1rem; }
  .geri-wrapper {
    padding-left: 18px; }
  .harita {
    height: 250px; }
  .ic-menu--paket .ic-menu__scroll {
    height: auto; }
  .paket-baslik {
    font-size: 1.2rem;
    margin-left: 0; }
  .paket--wrapper {
    margin-top: 25px;
    width: 100%;
    padding: 0 15px; }
  .sepet-icon {
    top: -6px;
    right: 15px;
    left: auto; }
  .sayfa__wrapper {
    margin-left: 0;
    width: 100%; }
  .servis__resim {
    height: 160px;
    margin-bottom: 15px; }
  .servis__baslik {
    font-size: 1rem; }
  .servis__fiyat {
    font-size: 1.1rem;
    top: -2px; }
  .servis__currency {
    font-size: 0.9rem; }
  .servis__aciklama {
    font-size: 0.8rem; }
  .servis {
    margin-bottom: 15px;
    padding-bottom: 15px; }
  .servis__butonlar {
    margin-top: 15px; }
    .servis__butonlar .servis__number {
      width: 48%; }
      .servis__butonlar .servis__number input {
        height: 34px;
        line-height: 34px;
        font-size: 0.8rem; }
    .servis__butonlar .btn-sepet {
      width: 48%;
      margin-top: 0 !important; }
    .servis__butonlar .servis__btn {
      font-size: 0.8rem;
      height: 34px;
      line-height: 34px; }
  .btn {
    font-size: 0.8rem !important; }
  .sepet-wrapper img {
    width: 120px; }
  .icindekiler {
    font-size: 0.9rem; }
    .icindekiler span {
      font-size: 0.9rem; }
  .sepet-modal .sepet-adetler {
    margin-top: 15px !important;
    font-size: 0.9rem; }
    .sepet-modal .sepet-adetler input {
      font-size: 0.8rem; }
  .sepet-modal .sepet-fiyat {
    width: auto;
    display: inline-block;
    padding-top: 15px; }
  .modal-butonlar {
    margin-top: 15px;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .modal-butonlar .servis__btn {
      height: 34px;
      line-height: 34px;
      width: 48%;
      padding-left: 0;
      padding-right: 0;
      text-align: center; }
  .form-control,
  .servis__number input {
    height: 34px;
    line-height: 34px; }
  .sepet-urun__resim {
    display: none; }
  .sepet-urun__aciklama {
    font-size: 0.8rem;
    padding-left: 0;
    border: none;
    height: auto;
    margin-left: 0;
    width: 100%; }
  .sepet-urun {
    width: auto;
    flex-wrap: wrap;
    display: block; }
  .sepet-scroll {
    overflow: auto; }
  .sepet-scroll .sepet-table {
    width: 100%; }
  .sepet-fiyatlar__fiyat {
    display: none; }
  .sepet-fiyatlar__input {
    border: none;
    height: 34px;
    padding: 0; }
  .sepet-bilgiler {
    display: none; }
  .sepet-fiyatlar__tutar {
    font-size: 1rem; }
  .sepet-sil {
    font-size: 1rem; }
  .sepet-toplam {
    width: 100%;
    padding: 0;
    font-size: 1.1rem; }
  .sepet-toplam__baslik {
    border: none;
    padding-left: 0; }
  .sepet-table__ust {
    font-size: 1rem; }
  .sepet-sayfa {
    padding-top: 120px; }
    .sepet-sayfa .sayfa__baslik {
      font-size: 1.2rem !important; }
    .sepet-sayfa .ust-baslik {
      margin-left: 15px; }
    .sepet-sayfa .sayfa__altyazi {
      font-size: 0.9rem !important; }
  .icerik__baslik {
    font-size: 1.2rem; }
  .icerik__link,
  .icerik__yazi {
    font-size: 0.9rem; }
  .icerik__resim {
    height: 250px; }
  .icerik__text {
    margin-top: 40px; }
  .etkinlik-ic-sayfa .ic-menu {
    padding-top: 140px; }
  .etkinlik-ic-sayfa .ic-menu--fixed {
    display: none; }
  .slider {
    width: calc(100% - 30px);
    left: 15px; }
    .slider .image {
      width: 100%; }
      .slider .image img {
        width: 120%;
        max-width: 120%; }
      .slider .image__text {
        font-size: 1rem;
        padding-left: 15px;
        padding-right: 15px; }
    .slider .image__resim:after {
      width: 120%;
      max-width: 120%; }
  .dil-linkler {
    position: relative;
    right: auto;
    top: 0;
    margin-bottom: 15px; }
  .dil-link {
    margin-left: 0;
    margin-right: 15px; }
  .oneri__resim {
    width: 70px; }
  .oneri__baslik {
    margin-bottom: 5px; } }

.popup, .popup-bg {
  display: none; }
