@media (max-width: 768px) {
  .slider-detay {
    padding: 110px 15px 280px;
  }

  .navigation {
    width: 50px;
    padding-left: 0;
    padding-right: 0;
    padding-top: 45px;
  }

  .btn-mobil {
    transform: scale(.7);
    padding: 0;
    padding-right: 5px;
    top: 20px;
  }

  .site {
    max-width: 100%;
  }

  .navigation__link {
    width: 28px;
    height: 28px;
    font-size: 0.9rem;
  }

  .navigation__arrow {
    svg {
      width: 12px;
    }
  }

  .paket {
    right: 60px;
  }

  .sef__baslik {
    font-size: 2rem;
  }

  .hikaye--1 .hikaye__card {
    width: 80%;
    padding-left: 30px;
    padding-right: 30px;
  }

  .hikaye--2 .hikaye__card {
    padding-left: 30px;
    padding-right: 30px;
  }

  .hikaye--3 .hikaye__card {
    padding-left: 30px;
    padding-right: 30px;
  }

  .hikaye--3 .hikaye__card {
    width: 90%;
  }

  .hikaye--4 .hikaye__card {
    padding: 120px 30px 60px;
  }

  .hikaye--4 .hikaye__gorsel {
    width: 90px;
    right: -15px;
  }

  .mutfak-baslik{
    font-size: 3.2rem;
  }

  .mutfak-slider{
    width: 100%;
  }

  .baslik-bg{
    display: none;
  }

  .mutfak-slider{
    padding-left: 0;
  }

  .hikaye--4 .hikaye__bg{
    width: 70%;
  }

  .urun{
    width: 250px;
  }

  .scroll--galeri .urun{
    width: 500px;
  }

  .icerik__baslik{
    font-size: 1.6rem;
  }

  .servis__butonlar{
    justify-content: space-between;
    .servis__number{
      width: 100%;
    }
    .servis__btn{
      width: 100%;
    }
  }

  .sepet-modal{
    width: calc(100% - 30px);
  }

  .modal-butonlar{
    text-align: center;

    a{
      margin: 15px 0 !important;
    }
  }

  .sepet-modal{
    padding: 15px;
  }
}
