@font-face {
    font-family: 'dinMedium';
    src: url('../../dist/font/subset-DINMediumTr.woff2') format('woff2'),
        url('../../dist/font/subset-DINMediumTr.woff') format('woff'),
        url('../../dist/font/subset-DINMediumTr.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'dinLight';
    src: url('../../dist/font/subset-DINTLightTr.woff2') format('woff2'),
        url('../../dist/font/subset-DINTLightTr.woff') format('woff'),
        url('../../dist/font/subset-DINTLightTr.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'dinBlack';
    src: url('../../dist/font/subset-DINBlackTr.woff2') format('woff2'),
        url('../../dist/font/subset-DINBlackTr.woff') format('woff'),
        url('../../dist/font/subset-DINBlackTr.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'din';
    src: url('../../dist/font/subset-DINTr.woff2') format('woff2'),
        url('../../dist/font/subset-DINTr.woff') format('woff'),
        url('../../dist/font/subset-DINTr.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'dinBold';
    src: url('../../dist/font/subset-DINTBoldTr.woff2') format('woff2'),
        url('../../dist/font/subset-DINTBoldTr.woff') format('woff'),
        url('../../dist/font/subset-DINTBoldTr.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

$roboto: 'Roboto Mono', monospace;
$ff_din: 'din', sans-serif;
$ff_dinLight: 'dinLight', sans-serif;
$ff_dinMedium: 'dinMedium', sans-serif;
$ff_dinBold: 'dinBold', sans-serif;
$ff_dinBlack: 'dinBlack', sans-serif;
