@media (max-width:1800px) {
  .mutfak-yazi{
    width: 45%;
  }

  .baslik-bg{
    width: calc(100% - 120px);
  }
}

@media (max-width:1600px) {
  .slider-detay__text{
    width: 50%;
  }
  .slider-detay__baslik{
    font-size: 3.5rem;
  }
  .slider-detay{
    padding: 30px;
  }

  .hikaye--1{
    .hikaye__gorsel{
      width: 150px;
    }

    .hikaye__card{
      padding: 60px;
    }
  }

  .hikaye--2{
     .hikaye__gorsel--2{
       width: 40%;
       right: -30px;
     }

     .hikaye__card{
       width: 100%;
       margin-left: 0;

       p{
         left: 0;
         width: 60%;
       }
     }
  }

  .mutfak-yazi{
    position: relative;
    left: 30px;
    bottom: 0;
    top: 0;
    margin-top: 120px;
    width: calc(100% - 100px);

    &:before{
      height: 200px;
    }
  }

  .hikaye--4{
    margin-top: 330px;
  }

  .hikaye--4 .hikaye__card{
    width: 80%;
  }

  .urunler{
    margin-left: 30px;
    width: calc(100% - 120px);
  }

  .etkinlik{
    width: 500px;

    &__alt{
      margin-top: -45px;
    }
  }

  .etkinlik--galeri{
    width: 100%;
    margin-left: 0;
  }

  .servis__butonlar{
    flex-wrap: wrap;
    justify-content: center;

    .servis__btn--yesil{
      width: 250px;
      margin-right: 10px;
      margin-top: 15px;
    }
  }
}

@media (max-width:1400px) {

  .servis__fiyat{
    right: 0;
    top: -10px;
  }

  .servis__baslik{
    font-size: 1.4rem;
  }

  .servis__butonlar{
    flex-wrap: wrap;
    justify-content: center;

    .servis__number{
      width: 100%;
      margin-bottom: 6px;
    }

    .servis__btn{
      width: 100%;
      margin: 6px 0 !important;

      &--yesil{
        width: 100%;
        margin: 6px 0;
      }
    }
  }

  .etkinlik{
    width: 350px;
    position: relative;

    &__bg{
      height: 300px;
    }

    &__alt{
      top: 0;
    }

    &__text{
      padding-top: 30px;
      width: 100%;
      margin-left: 0;
    }

    &__tarih{
      position: absolute;
      left: 15px;
      top: -95px;
    }
  }
  .hakkimizda-yazi{
    width: 100%;
    margin-top: 175px;
  }
  .ic-menu{
    width: 280px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .hakkimizda__item{
    padding: 120px 40px;
  }

  .ic-wrapper{
    width: calc(100% - 280px);
  }

  .sef{
    flex-wrap: wrap;

    &__resim{
      width: 300px;
      margin-left: auto;
      margin-right: auto;
      max-width: 100%;
    }

    &__bilgi{
      text-align: center;
      width: 100%;
      padding-top: 30px;
      padding-left: 0;
    }
  }

  .sefresim--1{
    top: 0;
    margin-top: 30px;
  }

  .hikaye--1{
    .hikaye__gorsel{
      width: 90px;
      top: -90px;
    }
  }

  .hikaye--2{
     .hikaye__gorsel--2{
       width: 240px;
       bottom: -90px;
       top: auto;
       transform: none;
     }

     .hikaye__card{
       width: 100%;
       margin-left: 0;
       padding: 160px 60px;
       margin-bottom: 30px;

       p{
         width: 100%;
       }
     }
  }

  .hikaye--3 .hikaye__card{
    width: 70%;
  }


  .icerik{

    &__alt{
      position: relative;
    }

    &__text{
      margin-left: 0;
      margin-top: 60px;
    }
  }


}


@media (max-width:1200px) {

  .paket-wrapper{
    width: calc(100% - 60px);
  }

  .hakkimizda{
    width: calc(100% + 60px);
    margin-left: -30px;
  }
  .site-navigation {
    left: -300px;
    z-index: 10001;
    @include efekt(left);
  }

  .mobil-bg {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    background: $renkss;
    width: 100%;
    height: 100%;
    transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
    z-index: 10001;
  }

  .arka-active {
    display: block;
    opacity: 1;
  }

  .mobil-active {
    left: 0;
  }

  .navigation{
    padding-top: 60px;
  }

  .btn-mobil {
    display: block;
    position: fixed;
    top: 12px;
    right: 0;
    z-index: 10035;
    outline: transparent;

    // &:after{
    //   content: '';
    //   position: absolute;
    //   left: -7px;
    //   top: -7px;
    //   width: calc(100% + 7px);
    //   height: calc(100% + 7px);
    //   background: #002f3b;
    //   z-index: 0;
    // }
    //
    // span{
    //   position: relative;
    //   z-index: 2;
    // }
  }

  .site {
    margin-left: 0;
    max-width: 100%;
    padding-left: 0;
  }

  .urunler{
    width: calc(100% - 60px);
  }

  .urun{
    width: 300px;
  }

  .slider-detay{
    padding: 30px;
  }

  .progress-wrapper{
    width: calc(100% - 120px);
    left: 60px;
    right: 60px;
  }

  .slider-detay__baslik{
    font-size: 3rem;
  }

  .paket{
    top: 15px;
    right: 90px;
  }

  .hikaye-wrapper{
    padding: 30px;
    padding-bottom: 0;
  }

  .slider{
    left: 30px;
    width: calc(100% - 70px);
  }

  .etkinlik--galeri{
    width: 100%;
  }

  .icerik{
    padding: 0 80px;
  }

  .sayfa{
    width: 100%;
  }
}


@media(max-height:680px){
  .slider-detay{
    padding-bottom: 280px;
  }
}
