$renk_yesil: #4DA6CC;
$renk_mavi: #002F3B;
$renk_acik: #F2F2F2;
$renk_gri: #E9E9E9;
$renk_yazi: #828282;
$renk_cizgi: rgba(43, 41, 45, 0.2);
$renkss: rgba(0, 0, 0, .1);
$renkss-2: rgba(0, 0, 0, .2);
$renkss-3: rgba(0, 0, 0, .3);
$renkss-4: rgba(0, 0, 0, .4);
$renksb: rgba(255, 255, 255, .1);
$renksb-2: rgba(255, 255, 255, .2);
$renksb-3: rgba(255, 255, 255, .3);
$renksb-4: rgba(255, 255, 255, .4);
$renksb-8: rgba(255, 255, 255, .8);


.renk--mavi{
  color: $renk_mavi !important;
}

.renk--yesil{
  color: $renk_yesil !important;
}
