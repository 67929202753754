html {
  box-sizing: border-box;
  font-size: 100%;
  scroll-behavior: smooth;
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

*,
*:after,
*:before {
  box-sizing: inherit;
}

body {
  animation: fadeIn 0.5s;
  font-family: $ff_din;
  font-size: 18px;
  color: $renk_mavi;
  line-height: 1.8;
  height: -webkit-fill-available;
}

button,
input,
select,
textarea {
  font-family: $ff_din;
}

.noscroll {
  overflow: hidden;
}

.tab-panel {
  display: none;
}

.tab-active {
  display: block;
  animation: fadeIn 0.5s;
}

.tabcontent {
  animation: fadeIn 0.5s;
}

.fadeIn {
  animation: fadeIn 0.5s;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.slick-slide {
  outline: none !important;
}

.btn {
  @extend .efekt;
  display: inline-block;
  font-family: $roboto;
  color: $renk_yesil;
  padding: 6px 20px;
  border: 1px solid $renk_yesil;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background: $renk_yesil;
    color: #fff;
  }

  &--yesilbg {
    background: $renk_yesil;
    color: #fff;

    &:hover {
      background: $renk_mavi;
    }
  }

  &--radius{
    background: $renk_mavi;
    color: #fff;
    border-radius: 32px;
    border-color: $renk_mavi;
    font-size: 1rem;
    font-family: $ff_dinMedium;
    letter-spacing: 1px;
    padding: 10px 32px;

    &:hover{
      border-color: $renk_yesil;
    }
  }

  &--siparis{
    font-family: $ff_dinMedium;
    border-radius: 32px;
    padding: 6px 34px;
    font-size: 1rem;

    &:hover{
      border-color: $renk_mavi;
    }
  }
}

.slide-rotate-hor-top {
  animation: slide-rotate-hor-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) reverse both;
  -webkit-animation: slide-rotate-hor-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) reverse both;
}
@-webkit-keyframes slide-rotate-hor-top {
  0% {
    -webkit-transform: translateY(0) rotateX(0deg);
    transform: translateY(0) rotateX(0deg);
  }

  100% {
    -webkit-transform: translateY(-150px) rotateX(-90deg);
    transform: translateY(-150px) rotateX(-90deg);
  }
}
@keyframes slide-rotate-hor-top {
  0% {
    -webkit-transform: translateY(0) rotateX(0deg);
    transform: translateY(0) rotateX(0deg);
  }

  100% {
    -webkit-transform: translateY(-150px) rotateX(-90deg);
    transform: translateY(-150px) rotateX(-90deg);
  }
}

.transition-fade {
  opacity: 1;
  transition: 0.4s;
}

html.is-animating .transition-fade {
  opacity: 0;
}

.baslik {
  display: inline-block;
  font-family: $ff_dinMedium;
  font-size: 3rem;
}

img {
  max-width: 100%;
}

.navigation-animasyon {
  animation: navigasyon ease-in-out 1s;
  animation-fill-mode: forwards;
}

.navigation-animasyon--reverse {
  animation: navigasyon2 ease-in-out 1s;
  animation-fill-mode: forwards;
}
@keyframes navigasyon {
  0% {
    transform: scale(1) rotate(0deg);
  }

  50% {
    transform: scale(.5) rotate(0deg);
  }

  100% {
    transform: scale(1) rotateX(180deg);
  }
}
@keyframes navigasyon2 {
  0% {
    transform: scale(1) rotateX(180deg);
  }

  50% {
    transform: scale(.5) rotate(0deg);
  }

  100% {
    transform: scale(1) rotate(0);
  }
}


hr{
  background: #E0E0E0;
  border: none;
  height: 1px;
}
